import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { storage, db, auth } from "../firebase";
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import {ScrollBox, ScrollAxes, FastTrack} from 'react-scroll-box'; // ES6



export function Homepage(props) {
  const navigate = useNavigate()
  const currentUser = auth.currentUser.uid

  const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
  const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
  const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")

  const [business_4_digit_code, set_business_4_digit_code] = useState("Loading... ")


  useEffect(() => {
      console.log("ADFSFL: ", process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID)
      if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
        navigate("/dealio_admin");
      }

      refresh_set_one_time_deal_recurring_deal_images_text()
      refresh_business_4_digit_code()
  }, [])

  const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
      const docRef = doc(db, "businesses", currentUser)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
          if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
              set_change_one_time_deal_button_text("Create One-Time Deal")
          } else {
              set_change_one_time_deal_button_text("Update One-Time Deal")
          }

          if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
              set_change_recurring_deal_button_text("Create Deal for Returning Customers")
          } else {
              set_change_recurring_deal_button_text("Update Deal for Returning Customers")
          }

          if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
              set_change_images_button_text("Upload Images")
          } else {
              set_change_images_button_text("Update Images")
          }


      }
  }

  const refresh_business_4_digit_code = async () => {
      const docRef = doc(db, "businesses", currentUser);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          set_business_4_digit_code(docSnap.data()["four_digit_code"]);
      }
  }


  const navigate_to_UpdateDeal = () => {
      navigate("/update_deal");
  }

  const navigate_to_UpdateImages = () => {
      navigate("/update_images");
  }

  const navigate_to_ViewProfile = () => {
    navigate("/view_profile");
  }

  const navigate_to_UpdateRecurringDeal = () => {
    navigate("/update_recurring_deal")
  }

  const navigate_to_ActivateDeactivateDeal = () => {
    navigate("/activate_deactivate_deal")
  }

  const navigate_to_ViewRedemptions = () => {
    navigate("/view_redemptions");
  }

  const navigate_to_Billing = () => {
    navigate("/billing");
  }

  const navigate_to_FAQs = () => {
    navigate("/frequently_asked_questions");
  }


  return (
    <>
      <div class="hero flex flex-col"></div>
    <div className="drawer drawer-mobile drawer-sm">
    <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
    <div className="drawer-content flex flex-row items-center justify-center bg-base-200">
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Dashboard</h1>
          <b><h2 style={{fontSize: "25px", marginTop: "20px"}}> Your business's unique 4-digit deal redemption code is: {business_4_digit_code} </h2></b>
          <p className="py-6">Welcome to the admin console! Here you can update your deals and your images!</p>
            <button className= "btn btn-primary m-1" onClick={navigate_to_UpdateDeal}> {change_one_time_deal_button_text} </button>
            <button className= "btn btn-primary m-1" onClick={navigate_to_UpdateRecurringDeal}> {change_recurring_deal_button_text} </button>
            <button className = "btn btn-primary m-1" onClick={navigate_to_UpdateImages}> Update Images </button>
            <button className = "btn btn-primary m-1" onClick={navigate_to_ViewProfile}> View My Profile </button>
        </div>

        
      </div>
    </div>
    
    </div> 
    <div className="drawer-side">
      <label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
      <ul className="menu p-4 w-80 bg-base-100 text-base-content">
        <li><a className = "active">Dashboard</a></li>
        <li><a onClick={navigate_to_UpdateDeal}> {change_one_time_deal_button_text} </a></li>
        <li><a onClick={navigate_to_UpdateRecurringDeal}> {change_recurring_deal_button_text} </a></li>
        <li><a onClick={navigate_to_UpdateImages}> {change_images_button_text} </a></li>
        <li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
        <li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
        <li><a onClick={navigate_to_ViewProfile}>View My Profile</a></li>
        <li><a onClick={navigate_to_Billing}>Billing</a></li>
        <li><a onClick={navigate_to_FAQs}>FAQs</a></li>
      </ul>
    
    </div>
  </div>
  </>
  )
}




export default Homepage;
