import React, { useContext, useState } from "react";
import {  Navigate } from "react-router";
import { AuthContext } from "../Auth.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, TextField, Alert, InputAdornment, IconButton, Input } from '@mui/material';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { storage, db, auth } from "../firebase";


function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}


const Login = ({ history }) => {
    const navigate = useNavigate();
    const [email_entered, set_email_entered] = useState("")
    const [password_entered, set_password_entered] = useState("")
    const [login_error_alert, set_login_error_alert] = useState([])

    const [show_password, set_show_password] = useState(false);
    const [password_text_input_type, set_password_text_input_type] = useState("password")

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email_entered, password_entered);
            const currentUser = auth.currentUser.uid

            if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
                navigate("/dealio_admin")
                return;
            } else {
                history.push("/");
            }
        } catch (error) {
            var error_message = error.code;
            if (error.code==="auth/user-not-found") {
                error_message = "There is no account associated with this email address. ";
            } else if (error.code==="auth/wrong-password") {
                error_message = "Incorrect password. Please try again. ";
            } else if (error.code==="auth/invalid-email") {
                error_message = "Invalid Email. Please try again. ";
            } else if (error.code==="auth/internal-error") {
                error_message="A miscellaneous error has occurred. Please fix email/password and try again. "
            }
            console.log(error);
            set_login_error_alert([
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_login_error_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >
                    {error_message}
                </Alert>
            ])
        }
    }

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
            return <Navigate to="/dealio_admin" />
        }
        return <Navigate to="/" />;
    }

    const text_field_styling = {
        marginTop: "20px"
    }

    const handle_password_type_toggle = async() => {
        const new_show_password = !show_password
        set_show_password(new_show_password);
        if (new_show_password === true) {
            set_password_text_input_type("text")
        } else {
            set_password_text_input_type("password")
        }
    }



    return (
        <>
        <div className="navbar bg-base-100">
        <div className="navbar-start">
            <a className="btn btn-ghost normal-case text-xl">Dealio</a>
        </div>
        <div className="navbar-center hidden lg:flex">
        </div>

        <div className="navbar-end">
                <a className="btn" onClick={() => {navigate("/signup")}}>Sign Up</a>
        </div> 
        
        </div>
        <div class="hero flex flex-col">
            <h1> Login to your Dealio Admin Account </h1>

            {login_error_alert}

            <div className="">
            <form onSubmit={handleLogin}>
                <Input placeholder="Email" className="input w-full max-w-xs mb-3"
                           value={email_entered}
                           onChange={(event) => {set_email_entered(event.target.value)}}
                           variant="outlined"
                           type="email"
                           style={text_field_styling} />

                <Input placeholder="Password" className="input w-full max-w-xs"
                           value={password_entered}
                           onChange={(event) => {set_password_entered(event.target.value)}}
                           variant="outlined"
                           type={password_text_input_type}
                           style={text_field_styling}
                           endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handle_password_type_toggle}>
                                  {show_password ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            } />

                <Button type="submit" className="btn" variant="contained"
                        style={{display: "block", margin: "auto", marginTop: "20px", marginBottom: "20px"}} onClick={handleLogin}>
                    Log In
                </Button>


            </form>
            </div>

            <Button 
                    onClick={() => {navigate("/password_reset")}}>
                reset my password
            </Button> 

            <h1 style={{marginTop: "30px", fontSize: "20px"}}> or </h1>
            <Button variant="contained"
                    style={{fontSize: "20px"}} 
                    onClick={() => {navigate("/signup")}}>
                Sign Up
            </Button> 

        </div>
        </>
    );
};

export default withRouter(Login);
