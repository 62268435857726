// JavaScript
// src/firebase.js
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";


// Production DB (dealio)
const firebaseConfig = {
  apiKey: "AIzaSyCFrxx-OujpiZSlJpNhSTpZLIBv5I_Q12s",
  authDomain: "dealio-8fbf7.firebaseapp.com",
  projectId: "dealio-8fbf7",
  storageBucket: "dealio-8fbf7.appspot.com",
  messagingSenderId: "303363497308",
  appId: "1:303363497308:web:f0ffcfc1d61cb9b56ff599"
};

// Development DB (dealio-demo)
// const firebaseConfig = {
//   apiKey: "AIzaSyAE0tWqGtlzTUFywzSZqh04pBsjRWmZuWo",
//   authDomain: "dealio-demo.firebaseapp.com",
//   projectId: "dealio-demo",
//   storageBucket: "dealio-demo.appspot.com",
//   messagingSenderId: "606299110925",
//   appId: "1:606299110925:web:3c5fcacf307600a35a5f44"
// };







// JavaScript
// src/firebase.js
const app = initializeApp(firebaseConfig, "bus-side")
const db = getFirestore(app)
const storage = getStorage(app);
const auth = getAuth(app);


export {app, db, storage, auth}

