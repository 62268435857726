import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';



export function Billing(props) {

	const navigate = useNavigate()

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal")
	}

	const navigate_to_UpdateRecurringDeal = () => {
	    navigate("/update_recurring_deal")
	}

	const navigate_to_ActivateDeactivateDeal = () => {
		navigate("/activate_deactivate_deal")
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_ViewProfile = () => {
		navigate("/view_profile");
	}

	const navigate_to_FAQs = () => {
	    navigate("/frequently_asked_questions");
	}
	
    const currentUser = auth.currentUser.uid
 
	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")

	const [billings_content, set_billings_content] = useState([
			<div> 
				<b><h2 style={{marginTop:"50px"}}> Nothing here yet! Make sure to check back here at least once every 2 weeks! </h2></b> 
			</div>
		]);
	


	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
	  const docRef = doc(db, "businesses", currentUser)
	  const docSnap = await getDoc(docRef)

	  if (docSnap.exists()) {
	      if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
	          set_change_one_time_deal_button_text("Create One-Time Deal")
	      } else {
	          set_change_one_time_deal_button_text("Update One-Time Deal")
	      }

	      if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
	          set_change_recurring_deal_button_text("Create Deal for Returning Customers")
	      } else {
	          set_change_recurring_deal_button_text("Update Deal for Returning Customers")
	      }

	      if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
	          set_change_images_button_text("Upload Images")
	      } else {
	          set_change_images_button_text("Update Images")
	      }
	  }
	}


	const refresh_billings_content = async () => {
		const arr = [];

		const query1 =  query(collection(db, "billings"), where("business_id", "==", currentUser))
		const querySnapshot = await getDocs(query1);
        querySnapshot.forEach((doc) => {
        	var invoice_issued = "No" 
        	if (doc.data()["invoice_sent"] === true) {invoice_issued = "Yes"}
        	var payment_received = "No"
        	if (doc.data()["payment_received"] === true) {payment_received = "Yes"}

            arr.push(
            	<div> 
					<b><h2 style={{marginTop:"50px"}}> Date of Billing:  </h2></b> 
						<h2> {doc.data()["billing_month"] + "/" + doc.data()["billing_day_of_month"] + "/" + doc.data()["billing_year"]} </h2>
					<b><h2 style={{marginTop:"15px"}}> Number of Redemptions: </h2></b> 
						<h2> {doc.data()["num_redemptions"]} </h2>
					<b><h2 style={{marginTop:"15px"}}> Charge Amount: </h2></b> 
						<h2> {doc.data()["charge_amount_cents"] / 100} </h2>
					<b><h2 style={{marginTop:"15px"}}> Invoice Issued: </h2></b> 
						<h2> {invoice_issued} </h2>
					<b><h2 style={{marginTop:"15px"}}> Payment Received: </h2></b> 
						<h2> {payment_received} </h2>
				</div>
            )
        });

        if (arr.length > 0) {
        	set_billings_content(arr);
        }
		    
		
	}

	


	useEffect(() => {
		refresh_billings_content()
	}, [])

	

	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero min-h-screen bg-base-200">
			<div>
				<div>

					<h1 className="text-4xl font-bold mb-3"> Billings  </h1>
						<h3> *Each billing includes all deal redemptions within a 2-week period directly prior to the billing date. </h3>
						<h3> *To pay outstanding bills, access the Stripe Payment Link in the email registered to your account. </h3>
					{billings_content}

				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
				<li><a onClick={navigate_to_UpdateDeal}>{change_one_time_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateRecurringDeal}>{change_recurring_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
				<li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
				<li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
				<li><a onClick={navigate_to_ViewProfile}>View Profile</a></li>
				<li><a  className = "active">Billing</a></li>
				<li><a onClick={navigate_to_FAQs}>FAQs</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}



export default Billing;
