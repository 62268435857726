
import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc, and, or } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';



export function AdminViewAllBusinesses(props) {

	const navigate = useNavigate()
    const currentUser = auth.currentUser.uid

    const [business_details, set_business_details] = useState([])


    const navigate_to_NewBusinessApproval = () => {
    	navigate("/dealio_admin")
    }
  	const navigate_to_AdminBilling = () => {
    	navigate("/admin_billing")
    }
    const navigate_to_ViewAllBusinesses = () => {
    	navigate("/admin_view_all_businesses")
    }
    const navigate_to_UpdateBusinessInfo = () => {
    	navigate("/admin_update_business_info")
    }
    const navigate_to_AdminViewMetrics = () => {
    	navigate("/admin_view_metrics")
    }

    const format_phone_number = (raw_phone_number) => {
    	if (raw_phone_number.length != 10) {
    		return raw_phone_number;
    	}
    	return raw_phone_number.substring(0, 3) + "-"
    			+ raw_phone_number.substring(3, 6) + "-"
    			+ raw_phone_number.substring(6);
    }

	
	const refresh_all_businesses_details = async () => {
		const arr = [];
		const q = query(collection(db, "businesses"));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			const email = doc.data()["user_email"];
			const business_name = doc.data()["business_name"];
			const business_four_digit_code = doc.data()["four_digit_code"];
			const business_address = doc.data()["business_address_street_address"] + " "
						                 + doc.data()["business_address_city"] + " "
						                 + doc.data()["business_address_state"] + " "
						                 + doc.data()["business_address_zip_code"] + " ";
			const business_formatted_address = doc.data()["business_formatted_address"];
			const business_category = doc.data()["business_category"]
			const business_phone_number = format_phone_number(doc.data()["business_phone_number"])
			const business_city_zip_code = doc.data()["business_address_city"] + " " + doc.data()["business_address_zip_code"]

			const user_full_name = doc.data()["user_first_name"] + " " + doc.data()["user_last_name"];

			var address_used;
			if (business_formatted_address != null && business_formatted_address.length > 0) {
				address_used = business_formatted_address;
			} else {
				address_used = business_address;
			}


			arr.push(
				<div style={{margin: "50px"}}> 
					<b><h2 style={{marginTop:"15px", fontSize:"25px"}}> Business Name: </h2></b> 
						<h2> {business_name} </h2>
					<b><h2 style={{marginTop:"15px"}}> Our contact: </h2></b> 
						<h2> {user_full_name} </h2>
					<b><h2 style={{marginTop:"15px"}}> Email: </h2></b> 
						<h2> {email} </h2>
					<b><h2 style={{marginTop:"15px"}}> Phone Number: </h2></b> 
						<h2> {business_phone_number} </h2>
					<b><h2 style={{marginTop:"15px"}}> 4-Digit Code: </h2></b> 
						<h2> {business_four_digit_code} </h2>	
					<b><h2 style={{marginTop:"15px"}}> Address: </h2></b> 
						<h2> {address_used} </h2>
					<b><h2 style={{marginTop:"15px"}}> Category: </h2></b> 
						<h2> {business_category} </h2>

				</div>
			);
		});

		set_business_details(arr);
	}

	useEffect(() => {
		refresh_all_businesses_details()
	}, [])



	if (currentUser !== process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
		return (
			<div>
				<h1> You do not have access to this page. </h1>
			</div>
		)
	}

	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200">
			<div>
				<div>

					<h1 className="text-4xl font-bold mb-3"> Admin: View All Businesses </h1>
					{business_details}
				
				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_NewBusinessApproval}>New Business Approval</a></li>
				<li><a onClick={navigate_to_AdminBilling}>Admin Billing</a></li>
				<li><a className = "active"> View All Businesses </a></li>
				<li><a onClick={navigate_to_UpdateBusinessInfo}>Update Business Info</a></li>
				<li><a onClick={navigate_to_AdminViewMetrics}>View Metrics</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default AdminViewAllBusinesses;
