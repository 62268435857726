import React, { useContext, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {  Navigate } from "react-router";
import { AuthContext } from "../Auth.js";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { db, auth } from "../firebase";
import RadioGroup  from '@mui/material/RadioGroup'

import { Button, TextField, Alert, FormControlLabel, Radio } from '@mui/material';
import { useLocation,useNavigate,useParams } from "react-router-dom";

export function TermsOfServiceForBusinesses(props) {

  return (
        <div>

            <div className="navbar bg-base-100">
            <div className="navbar-start">
            </div>
            <div className="navbar-center hidden lg:flex">
            </div>
          
            </div>
            <div class="hero flex flex-col">
            
                <p style={{textAlign: "center", marginTop: "50px"}}> Dealio Terms of Service </p>
                <p style={{textAlign: "center"}}> Last Updated: June 15, 2023 </p>
                <p style ={{margin: "100px", marginTop: "10px", marginBottom: "30px"}}>
                                    <br></br>
                                    Welcome to the Dealio, Inc. (“Dealio,” “we,” “us,” or “`our”) website (the “Site”). Please read these Terms of
                                    Service (the “Terms”) and our Privacy Policy (www.beta.joindealio.com) (“Privacy Policy”) carefully because they
                                    govern your use of our Site and our products and services accessible via our Site. To make these Terms easier
                                    to read, the Site and our services are collectively called the “Services.”
                                    <br></br>
                                    1. Agreement to Terms. By using our Services, you agree to be bound by these Terms. If you don’t agree
                                    to be bound by these Terms, do not use the Services. If you are accessing and using the Services on behalf of a
                                    company (such as your employer) or other legal entity, you represent and warrant that you have the authority
                                    to bind that company or other legal entity to these Terms. In that case, “you” and “your” will refer to that
                                    company or other legal entity.
                                    <br></br>
                                    2. Privacy Policy. Please refer to our Privacy Policy for information on how we collect, use and disclose
                                    information from our users. You acknowledge and agree that your use of the Services is subject to our Privacy
                                    Policy.

                                    <br></br>
                                    IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING
                                    (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND DEALIO THROUGH BINDING,
                                    INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 18 “DISPUTE
                                    RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION (INCLUDING THE PROCEDURE TO OPT OUT OF
                                    ARBITRATION).
                                    <br></br>
                                    3. Description and Nature of the Services.
                                    <br></br>
                                    (a) The Services are a platform through which merchants can offer deals on goods, services or
                                    experiences (“Promotion(s)”) and local shoppers can search and redeem those deals. You are either a)
                                    a user of the Services as a customer browsing for or redeeming Promotions on the Services (“End
                                    User”), or b) a merchant listing Promotions to the Services (“Merchant”).
                                    <br></br>
                                    (b) Merchants may publish a Promotion to the Services with relevant information describing the
                                    applicable Promotion, including any applicable timeframes for redemption, eligibility, applicability,
                                    specifications and other terms, restrictions or requirements (“Promotion Criteria”). Merchants are
                                    responsible for keeping all Promotions and corresponding Promotion Criteria up to date. As a
                                    Merchant, you represent and warrant that you are entitled to offer the Promotion and you will honor
                                    the Promotion according to the specified Promotion Criteria. As an End User, you can only redeem a
                                    Promotion once, unless otherwise provided in the Promotion Criteria. If your Promotion Criteria is out
                                    of date, inaccurate, or otherwise in violation of these Terms, you acknowledge and agree that Dealio
                                    may remove the Promotion from the Services without limitation to any other remedies available to
                                    Dealio hereunder.
                                    <br></br>
                                    (c) We merely facilitate interactions with Merchants and End Users or other users of the Services,
                                    and make no representations or warranties about the Merchants, End Users, or any Promotions or
                                    Promotion Criteria. All agreements entered into with respect to Promotions are between Merchants
                                    and End Users, and Dealio is not a party to those transactions or agreements. Merchants and End
                                    Users acknowledge that Dealio is not acting as an agent in any capacity for any Merchants, End Users
                                    or third parties, and will not be responsible for supervising, enforcing or monitoring any agreements or
                                    transactions that result from the Services or the fulfillment and redemption of any Promotions.
                                    Neither Merchants nor End Users are employees, agents, joint ventures or partners of Dealio for any 
                                    reason, and Dealio does not, and shall not be deemed to, direct or control anyone in connection with
                                    the Services.
                                    <br></br>
                                    (d) You are solely responsible for evaluating, assessing, and claiming the Promotion and assume
                                    all risk in connection with your redemption of the Promotion via the functionality of the Services.

                                    <br></br>
                                    4. Changes to Terms or Services. We may update the Terms at any time, in our sole discretion. If we do
                                    so, we’ll let you know either by posting the updated Terms on the Site or through other communications. It’s
                                    important that you review the Terms whenever we update them or you use the Services. If you continue to use
                                    the Services after we have posted updated Terms, you are agreeing to be bound by the updated Terms. If you
                                    don’t agree to be bound by the updated Terms, then you must immediately discontinue use of the Services.
                                    Because our Services are evolving over time we may change or discontinue all or any part of the Services, at
                                    any time and without notice, at our sole discretion.
                                    <br></br>
                                    5. Who May Use the Services?
                                    <br></br>
                                    (a) Eligibility. You may use the Services only if you are 18 years or older and capable of forming a
                                    binding contract with Dealio and are not barred from using the Services under applicable law.
                                    <br></br>
                                    (b) Registration and Your Information. If you want to use certain features of the Services you may
                                    have to create an account (“Account”).
                                    <br></br>
                                    (c) Accuracy of Account Information. It’s important that you provide us with accurate, complete
                                    and up-to-date information for your Account and you agree to update such information to keep it
                                    accurate, complete and up-to-date. If you don’t, we may suspend or terminate your Account. You
                                    agree that you won’t disclose your Account password to anyone and you’ll notify us immediately of
                                    any unauthorized use of your Account. You’re responsible for all activities that occur under your
                                    Account, whether or not you know about them.
                                    <br></br>
                                    6. Fees. Dealio requires payment of a fee for use of the Services (or certain portions thereof) and you
                                    agree to pay such fees. You have the option of making a one-time payment (“One-Time Payment”) or
                                    purchasing a subscription (“Subscription”) for such use.
                                    <br></br>
                                    (a) Payments. Whether you make a One-Time Payment or purchase a Subscription (each, a
                                    “Transaction”), you expressly authorize us (or our third-party payment processor) to charge you for
                                    such Transaction. All payments made for the Services to Dealio will be made in U.S. dollars. We may
                                    ask you to supply additional information relevant to your Transaction, including your credit card
                                    number, the expiration date of your credit card and your email and postal addresses for billing and
                                    notification (such information, “Payment Information”). You represent and warrant that you have the
                                    legal right to use all payment method(s) represented by any such Payment Information. When you
                                    initiate a Transaction, you authorize us to provide your Payment Information to third parties so we can
                                    complete your Transaction and to charge your payment method for the type of Transaction you have
                                    selected (plus any applicable taxes and other charges). You may need to provide additional
                                    information to verify your identity before completing your Transaction (such information is included
                                    within the definition of Payment Information). By initiating a Transaction, you agree to the pricing,
                                    payment and billing policies applicable to such fees and charges, as posted or otherwise
                                    communicated to you. All payments for Transactions are non-refundable and non-transferable except
                                    as expressly provided in these Terms. All fees and applicable taxes, if any, are payable in United States
                                    dollars. You hereby authorize Dealio, through a third-party credit card processor, to charge your credit
                                    card for the applicable fees. The payments described in this Section are separate from any payments,
                                    if applicable, made to the Merchant by the End User outside of the Services, which are governed by
                                    separate terms between the End User and the Merchant and/or the Merchant’s third-party payment
                                    processor.
                                    <br></br>
                                    (b) Auto-renewal. If you purchase a Subscription, you will be charged the monthly Subscription
                                    fee, plus any applicable taxes, and other charges (“Subscription Fee”), at the beginning of your
                                    Subscription and each month thereafter, at the then-current Subscription Fee. BY PURCHASING A
                                    SUBSCRIPTION, YOU AUTHORIZE DEALIO TO INITIATE RECURRING NON-REFUNDABLE PAYMENTS AS
                                    SET FORTH BELOW. If you purchase a Subscription, we (or our third-party payment processor) will
                                    automatically charge you each year on the anniversary of the commencement of your Subscription,
                                    using the Payment Information you have provided until you cancel your Subscription. Dealio will send
                                    you a reminder with the then-current Subscription Fee in accordance with applicable law. By agreeing
                                    to these Terms and electing to purchase a Subscription, you acknowledge that your Subscription has
                                    recurring payment features and you accept responsibility for all recurring payment obligations prior to
                                    cancellation of your Subscription by you or Dealio. Your Subscription continues until cancelled by you
                                    or we terminate your access to or use of the Services or Subscription in accordance with these Terms.
                                    <br></br>
                                    (c) Cancelling One-Time Payment or Subscription. All sales are final. YOU WILL NOT BE ABLE TO
                                    CANCEL THE PURCHASE AND/OR RECEIVE A REFUND OF YOUR ONE-TIME PAYMENT OR SUBSCRIPTION
                                    FEE AT ANY TIME. But if something unexpected happens in the course of completing a Transaction, we
                                    reserve the right to cancel your Transaction for any reason; if we cancel your Transaction we’ll refund
                                    any payment you have already remitted to us for such Transaction. Without limiting the foregoing, you
                                    may cancel your Subscription at any time via the functionality of the Services, but please note that
                                    such cancellation will be effective at the end of the then-current Subscription period. EXCEPT AS SET
                                    FORTH ABOVE WITH RESPECT TO YOUR INITIAL SUBSCRIPTION PURCHASE, YOU WILL NOT RECEIVE A
                                    REFUND OF ANY PORTION OF THE SUBSCRIPTION FEE PAID FOR THE THEN CURRENT SUBSCRIPTION
                                    PERIOD AT THE TIME OF CANCELLATION. You will be responsible for all Subscription Fees (plus any
                                    applicable taxes and other charges) incurred for the then-current Subscription period. If you cancel,
                                    your right to use the Services will continue until the end of your then current Subscription period and
                                    will then terminate without further charges.
                                    <br></br>
                                    7. Feedback. We welcome feedback, comments and suggestions for improvements to the Services
                                    (“Feedback”). You can submit Feedback by emailing us at the address indicated below. You grant to us a non-
                                    exclusive, transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free license, with the right to
                                    sublicense, under any and all intellectual property rights that you own or control to use, copy, modify, create
                                    derivative works based upon and otherwise exploit the Feedback for any purpose.
                                    <br></br>
                                    8. Content and User Content.
                                    <br></br>
                                    (a) Definitions. For purposes of these Terms: (i) “Content” means text, graphics, images, video,
                                    works of authorship of any kind, and information or other materials that are posted, generated,
                                    provided or otherwise made available through the Services; and (ii) “User Content” means any
                                    Content that Account holders (including you) provide to be made available through the Services.
                                    Content includes without limitation User Content.
                                    <br></br>
                                    (b) Our Content Ownership. Dealio does not claim any ownership rights in any User Content and
                                    nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit your
                                    User Content. Subject to the foregoing, Dealio and its licensors exclusively own all right, title and
                                    interest in and to the Services and Content, including all associated intellectual property rights. You
                                    acknowledge that the Services and Content are protected by copyright, trademark, and other laws of
                                    the United States and foreign countries. You agree not to remove, alter or obscure any copyright,
                                    trademark, service mark or other proprietary rights notices incorporated in or accompanying the
                                    Services or Content.
                                    <br></br>
                                    (c) Rights in User Content Granted by You. By making any User Content available through the
                                    Services you hereby grant to Dealio a non-exclusive, transferable, worldwide, royalty-free license, with
                                    the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly
                                    display, and publicly perform your User Content in connection with operating and providing the
                                    Services.
                                    <br></br>
                                    (d) Your Responsibility for User Content. You are solely responsible for all your User Content. You
                                    represent and warrant that you own all your User Content or you have all rights that are necessary to
                                    grant us the license rights in your User Content under these Terms. You also represent and warrant
                                    that neither your User Content, nor your use and provision of your User Content to be made available
                                    through the Services, nor any use of your User Content by Dealio on or through the Services will
                                    infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or
                                    privacy, or result in the violation of any applicable law or regulation.
                                    <br></br>
                                    (e) Rights in Content Granted by Dealio. Subject to your compliance with these Terms, Dealio
                                    grants to you a limited, non-exclusive, non-transferable license, with no right to sublicense, to
                                    download, view, copy, display and print the Content solely in connection with your permitted use of
                                    the Services.
                                    <br></br>
                                    9. Ratings &amp; Reviews.
                                    <br></br>
                                    (a) General. Within a certain timeframe after redeeming the Promotion, End Users may leave
                                    comments (a “Review”) and/or submit a rating (“Rating”) about the Merchant or Promotion, as
                                    applicable. Ratings or Reviews reflect the opinions of individual users and do not reflect the opinion of
                                    Dealio. Ratings and Reviews may be surfaced elsewhere on the Services together with other relevant
                                    information. Ratings and Reviews are not verified by Dealio for accuracy and may be incorrect or
                                    misleading. For the avoidance of doubt, Ratings and Reviews shall be User Content as defined herein. If
                                    you come across a review that you feel is misleading or may violate these Terms, please let us know by
                                    reporting it via the functionality of the Services, or by emailing us at contact@joindealio.com.
                                    <br></br>
                                    (b) Review Requirements. Ratings and Reviews by End Users must be accurate and honest and
                                    may not contain any offensive or defamatory language. When you post a Review, you acknowledge
                                    and agree that the Review is subject to these Terms of Service and Dealio’s Copyright Policy. All End
                                    Users and Merchants are expressly prohibited from manipulating the Ratings and Reviews system in
                                    any manner, such as instructing a third party to provide or write a positive or negative Rating or
                                    Review.
                                    <br></br>
                                    10. General Prohibitions and Dealio’s Enforcement Rights. You agree not to do any of the following:
                                    <br></br>
                                    (a) Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates
                                    or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual
                                    property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would
                                    violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false,
                                    misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes
                                    discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is
                                    violent or threatening or promotes violence or actions that are threatening to any person or entity; or
                                    (vii) promotes illegal or harmful activities or substances;
                                    <br></br>
                                    (b) Use, display, mirror or frame the Services or any individual element within the Services,
                                    Dealio’s name, any Dealio trademark, logo or other proprietary information, or the layout and design
                                    of any page or form contained on a page, without Dealio’s express written consent;
                                    <br></br>
                                    (c) Access, tamper with, or use non-public areas of the Services, Dealio’s computer systems, or
                                    the technical delivery systems of Dealio’s providers;
                                    <br></br>
                                    (d) Attempt to probe, scan or test the vulnerability of any Dealio system or network or breach any
                                    security or authentication measures;
                                    <br></br>
                                    (e) Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any
                                    technological measure implemented by Dealio or any of Dealio’s providers or any other third party
                                    (including another user) to protect the Services or User Content;
                                    <br></br>
                                    (f) Attempt to access or search the Services or User Content or download User Content from the
                                    Services through the use of any engine, software, tool, agent, device or mechanism (including spiders,
                                    robots, crawlers, data mining tools or the like) other than the software and/or search agents provided
                                    by Dealio or other generally available third-party web browsers;
                                    <br></br>
                                    (g) Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail,
                                    spam, chain letters or other form of solicitation;
                                    <br></br>
                                    (h) Use any meta tags or other hidden text or metadata utilizing a Dealio trademark, logo URL or
                                    product name without Dealio’s express written consent;
                                    <br></br>
                                    (i) Use the Services or User Content, or any portion thereof, for any commercial purpose (except
                                    in connection with your permitted use of the Services as a Merchant) or for the benefit of any third
                                    party or in any manner not permitted by these Terms;
                                    <br></br>
                                    (j) Forge any TCP/IP packet header or any part of the header information in any email or
                                    newsgroup posting, or in any way use the Services or User Content to send altered, deceptive or false
                                    source-identifying information;
                                    <br></br>
                                    (k) Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to
                                    provide the Services or User Content;
                                    <br></br>
                                    (l) Interfere with, or attempt to interfere with, the access of any user, host or network, including,
                                    without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
                                    <br></br>
                                    (m) Collect or store any personally identifiable information from the Services from other users of
                                    the Services without their express permission;
                                    <br></br>
                                    (n) Impersonate or misrepresent your affiliation with any person or entity;
                                    <br></br>
                                    (o) Violate any applicable law or regulation (including without limitation listing a Promotion that
                                    violates any applicable law or regulation); or
                                    <br></br>
                                    (p) Encourage or enable any other individual to do any of the foregoing.
                                    Although we’re not obligated to monitor access to or use of the Services or User Content or to review or edit
                                    User Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with
                                    these Terms and to comply with applicable law or other legal requirements. We reserve the right, but are not
                                    obligated, to remove or disable access to any User Content, at any time and without notice, including, but not
                                    limited to, if we, at our sole discretion, consider any User Content to be objectionable or in violation of these
                                    Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may
                                    also consult and cooperate with law enforcement authorities to prosecute users who violate the law.
                                    <br></br>
                                    11. DMCA/Copyright Policy. Dealio respects copyright law and expects its users to do the same. It is
                                    Dealio’s policy to terminate in appropriate circumstances Account holders who repeatedly infringe or are
                                    believed to be repeatedly infringing the rights of copyright holders. Dealio’s Copyright Policy can be found
                                    online at: [insert].
                                    <br></br>
                                    12. Links to Third Party Websites or Resources. The Services may contain links to third-party websites or
                                    resources. We provide these links only as a convenience and are not responsible for the content, products or
                                    services on or available from those websites or resources or links displayed on such websites. You
                                    acknowledge sole responsibility for and assume all risk arising from, your use of any third-party websites or
                                    resources.
                                    <br></br>
                                    13. Termination. We may terminate your access to and use of the Services, including without limitation
                                    your Account, at our sole discretion, at any time and without notice to you. You may cancel your Account at
                                    any time by sending an email to us at the contact information listed below. Upon any termination,
                                    discontinuation or cancellation of the Services or your Account, the following Sections will survive: 6 (for all
                                    payments due to Dealio prior to the expiration or termination hereof), 8(a) , 8(b) , 8(c) , 14, 15, 16, 17, 18, and
                                    19.
                                    <br></br>
                                    14. Warranty Disclaimers. THE SERVICES AND CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF
                                    ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
                                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND
                                    ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the
                                    Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make
                                    no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any
                                    Content.
                                    IN ADDITION, WE MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                                    REGARDING THE QUALITY OR SUITABILITY OF ANY PROMOTION, PROMOTION CRITERIA, MERCHANT, OR THE
                                    EXISTENCE, ACCURACY, OR LEGALITY OF ANY PROMOTION DESCRIBED. DEALIO HAS NO CONTROL OVER AND
                                    DOES NOT GUARANTEE (I) THE EXISTENCE, QUALITY, OR LEGALITY OF ANY PROMOTIONS, (II) THE TRUTH OR
                                    ACCURACY OF ANY CONTENT OR DATA MADE AVAILABLE THROUGH OR IN CONNECTION WITH THE SERVICES
                                    (INCLUDING THE PROMOTION CRITERIA), OR (III) THE PERFORMANCE OR CONDUCT OF ANY END USER,
                                    MERCHANT, OR THIRD PARTY.
                                    DEALIO PROVIDES NO ENDORSEMENT, CERTIFICATION OR GUARANTEE ABOUT ANY END USER, MERCHANT,
                                    OR PROMOTION, INCLUDING WHETHER THE END USER, MERCHANT, OR PROMOTION IS TRUSTWORTHY, SAFE
                                    OR SUITABLE (AS APPLICABLE). YOU SHOULD ALWAYS EXERCISE DUE DILIGENCE AND CARE WHEN YOU LIST A
                                    PROMOTION, REDEEM A PROMOTION FROM A MERCHANT, OR COMMUNICATE AND INTERACT WITH OTHER
                                    END USERS OR MERCHANTS, WHETHER ONLINE OR IN PERSON.
                                    <br></br>
                                    15. Indemnity. You will indemnify and hold harmless Dealio and its officers, directors, employees and
                                    agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses,
                                    including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with
                                    (i) your access to or use of the Services or Content, (ii) your User Content, (iii) any Promotion offered or
                                    redeemed by you, or (iv) your violation of these Terms.
                                    <br></br>
                                    16. Limitation of Liability.
                                    <br></br>
                                    (a) NEITHER DEALIO NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
                                    DELIVERING THE SERVICES OR CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY
                                    OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS,
                                    LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER
                                    DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF
                                    OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR
                                    CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
                                    LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT DEALIO OR ANY OTHER PARTY HAS
                                    BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
                                    HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW
                                    THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
                                    ABOVE LIMITATION MAY NOT APPLY TO YOU.
                                    <br></br>
                                    (b) IN NO EVENT WILL DEALIO’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH
                                    THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT EXCEED THE
                                    AMOUNTS YOU HAVE PAID TO DEALIO FOR USE OF THE SERVICES OR CONTENT OR ONE HUNDRED
                                    DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO DEALIO, AS APPLICABLE.
                                    <br></br>
                                    (c) THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
                                    ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN DEALIO AND YOU.
                                    <br></br>
                                    17. Governing Law and Forum Choice. These Terms and any action related thereto will be governed by
                                    the Federal Arbitration Act, federal arbitration law, and the laws of the State of California, without regard to its
                                    conflict of laws provisions. Except as otherwise expressly set forth in Section 18 “Dispute Resolution,” the
                                    exclusive jurisdiction for all Disputes (defined below) that you and Dealio are not required to arbitrate will be
                                    the state and federal courts located in the Northern District of California, and you and Dealio each waive any
                                    objection to jurisdiction and venue in such courts.
                                    <br></br>
                                    18. Dispute Resolution.
                                    <br></br>
                                    (a) Mandatory Arbitration of Disputes. We each agree that any dispute, claim or controversy
                                    arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or
                                    validity thereof or the use of the Services (collectively, “Disputes”) will be resolved solely by binding,
                                    individual arbitration and not in a class, representative or consolidated action or proceeding. You
                                    and Dealio agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of
                                    these Terms, and that you and Dealio are each waiving the right to a trial by jury or to participate in a
                                    class action. This arbitration provision shall survive termination of these Terms.
                                    <br></br>
                                    (b) Exceptions. As limited exceptions to Section 18(a) above: (i) we both may seek to resolve a
                                    Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other
                                    equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our
                                    intellectual property rights.
                                    <br></br>
                                    (c) Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by the
                                    American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then
                                    in effect, except as modified by these Terms. The AAA Rules are available at www.adr.org or by calling
                                    1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration
                                    to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form
                                    Demand for Arbitration at www.adr.org.
                                    Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree
                                    to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all
                                    issues relating to the interpretation, applicability, enforceability and scope of this arbitration
                                    agreement.
                                    <br></br>
                                    (d) Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by
                                    the AAA Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible
                                    for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration we’ll pay all of
                                    our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration
                                    you will be entitled to an award of attorneys’ fees and expenses to the extent provided under
                                    applicable law.
                                    <br></br>
                                    (e) Injunctive and Declaratory Relief. Except as provided in Section 18(b) above, the arbitrator
                                    shall determine all issues of liability on the merits of any claim asserted by either party and may award
                                    declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent
                                    necessary to provide relief warranted by that party’s individual claim. To the extent that you or we
                                    prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary
                                    purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the
                                    entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and
                                    not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be
                                    stayed pending the outcome of the merits of any individual claims in arbitration.
                                    <br></br>
                                    (f) Class Action Waiver. YOU AND DEALIO AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                                    OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
                                    IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties’ Dispute is
                                    resolved through arbitration, the arbitrator may not consolidate another person’s claims with your
                                    claims, and may not otherwise preside over any form of a representative or class proceeding. If this
                                    specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section
                                    shall be null and void.
                                    <br></br>
                                    (g) Severability. With the exception of any of the provisions in Section 18(f) of these Terms (“Class
                                    Action Waiver”), if an arbitrator or court of competent jurisdiction decides that any part of these
                                    Terms is invalid or unenforceable, the other parts of these Terms will still apply.
                                    <br></br>
                                    19. General Terms.
                                    <br></br>
                                    (a) Entire Agreement. These Terms constitute the entire and exclusive understanding and
                                    agreement between Dealio and you regarding the Services and Content, and these Terms supersede
                                    and replace any and all prior oral or written understandings or agreements between Dealio and you
                                    regarding the Services and Content. If any provision of these Terms is held invalid or unenforceable by
                                    an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum
                                    extent permissible and the other provisions of these Terms will remain in full force and effect. You may
                                    not assign or transfer these Terms, by operation of law or otherwise, without Dealio’s prior written
                                    consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null.
                                    Dealio may freely assign or transfer these Terms without restriction. Subject to the foregoing, these
                                    Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
                                    <br></br>
                                    (b) Notices. Any notices or other communications provided by Dealio under these Terms,
                                    including those regarding modifications to these Terms, will be given: (i) via email; or (ii) by posting to
                                    the Services. For notices made by e-mail, the date of receipt will be deemed the date on which such
                                    notice is transmitted.
                                    <br></br>
                                    (c) Waiver of Rights. Dealio’s failure to enforce any right or provision of these Terms will not be
                                    considered a waiver of such right or provision. The waiver of any such right or provision will be
                                    effective only if in writing and signed by a duly authorized representative of Dealio. Except as expressly
                                    set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be
                                    without prejudice to its other remedies under these Terms or otherwise.
                                    <br></br>
                                    20. Contact Information. If you have any questions about these Terms or the Services, please contact us
                                    as described below. If you have any dispute or disagreement with another user, including with a Merchant or
                                    End User, you may notify us at contact@joindealio.com within 48 hours of the redemption of your Promotion
                                    and we will endeavor to address such reported dispute or disagreement as we deem appropriate. You
                                    understand and acknowledge that failure to contact us within this timeframe may limit our ability to address
                                    the dispute or disagreement.

                                    Dealio, Inc.
                                    contact@joindealio.com
                </p>

            </div>
        </div>

    );

}

export default TermsOfServiceForBusinesses;
