import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc, and } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';
import { Line } from "react-chartjs-2";



export function ViewRedemptions(props) {

	const navigate = useNavigate()

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal")
	}

	const navigate_to_UpdateRecurringDeal = () => {
	    navigate("/update_recurring_deal")
	}

	const navigate_to_ActivateDeactivateDeal = () => {
		navigate("/activate_deactivate_deal")
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_ViewProfile = () => {
		navigate("/view_profile");
	}

	const navigate_to_Billing = () => {
		navigate("/billing");
	}

	const navigate_to_FAQs = () => {
	    navigate("/frequently_asked_questions");
	}
	
    const currentUser = auth.currentUser.uid
 
	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")

	const [num_redemptions_1_day, set_num_redemptions_1_day] = useState(0);
	const [num_redemptions_7_days, set_num_redemptions_7_days] = useState(0);
	const [num_redemptions_30_days, set_num_redemptions_30_days] = useState(0);

	const [num_views_1_day, set_num_views_1_day] = useState(0);
	const [num_views_7_days, set_num_views_7_days] = useState(0);
	const [num_views_30_days, set_num_views_30_days] = useState(0);

	const [graph_views_data_30_days, set_graph_views_data_30_days] = useState();



	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
	  const docRef = doc(db, "businesses", currentUser)
	  const docSnap = await getDoc(docRef)

	  if (docSnap.exists()) {
	      if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
	          set_change_one_time_deal_button_text("Create One-Time Deal")
	      } else {
	          set_change_one_time_deal_button_text("Update One-Time Deal")
	      }

	      if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
	          set_change_recurring_deal_button_text("Create Deal for Returning Customers")
	      } else {
	          set_change_recurring_deal_button_text("Update Deal for Returning Customers")
	      }

	      if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
	          set_change_images_button_text("Upload Images")
	      } else {
	          set_change_images_button_text("Update Images")
	      }


	  }
	}


	const refresh_redemptions_1_day = async () => {
		var beginning_date = new Date();
		beginning_date.setDate(beginning_date.getDate() - 1)

		const q = query(collection(db, "redemptions"), and(
				where("business_id", "==", currentUser),
				where('time', '>', beginning_date)
			))

		const querySnapshot = await getDocs(q);
		set_num_redemptions_1_day(querySnapshot.size);
	}

	const refresh_redemptions_7_days = async () => {
		var beginning_date = new Date();
		beginning_date.setDate(beginning_date.getDate() - 7)

		const q = query(collection(db, "redemptions"), and(
				where("business_id", "==", currentUser),
				where('time', '>', beginning_date)
			))

		const querySnapshot = await getDocs(q);
		set_num_redemptions_7_days(querySnapshot.size);
	}

	const refresh_redemptions_30_days = async () => {
		var beginning_date = new Date();
		beginning_date.setDate(beginning_date.getDate() - 30)

		const q = query(collection(db, "redemptions"), and(
				where("business_id", "==", currentUser),
				where('time', '>', beginning_date)
			))

		const querySnapshot = await getDocs(q);
		set_num_redemptions_30_days(querySnapshot.size);
	}

	const refresh_views_1_day = async () => {
		var beginning_date = new Date();

		console.log("Current time: ", beginning_date)

		beginning_date.setDate(beginning_date.getDate() - 1)

		console.log("One day ago: ", beginning_date)

		const q = query(collection(db, "views"), and(
				where("business_id", "==", currentUser),
				where('time', '>', beginning_date)
			))

		const querySnapshot = await getDocs(q);
		set_num_views_1_day(querySnapshot.size);
	}

	const refresh_views_7_days = async () => {
		var beginning_date = new Date();
		beginning_date.setDate(beginning_date.getDate() - 7)

		const q = query(collection(db, "views"), and(
				where("business_id", "==", currentUser),
				where('time', '>', beginning_date)
			))

		const querySnapshot = await getDocs(q);
		set_num_views_7_days(querySnapshot.size);
	}

	const refresh_views_30_days = async () => {
		var beginning_date = new Date();
		beginning_date.setDate(beginning_date.getDate() - 30)

		const q = query(collection(db, "views"), and(
				where("business_id", "==", currentUser),
				where('time', '>', beginning_date)
			))

		const querySnapshot = await getDocs(q);
		set_num_views_30_days(querySnapshot.size);
	}

	// const refresh_views_graph_30_days = async () => {

	// 	var dates = [];
	// 	var view_counts = [];


	// 	var curr_date = new Date();
	// 	for (var i = 0; i < 30; i++) {

	// 		var month = curr_date.getMonth() + 1;
	// 		var day_of_month = curr_date.getDate();
	// 		var year = curr_date.getYear() + 1900;

	// 		var beginning_date = new Date(year, month-1, day_of_month);
	// 		var ending_date = new Date(year, month-1, day_of_month);
	// 		ending_date.setDate(ending_date.getDate() + 1)

	// 		const views_on_day_query = query(collection(db, "views"), and(
	// 				where("business_id", "==", currentUser),
	// 				where("time", ">=", beginning_date),
	// 				where("time", "<", ending_date),
	// 			))


	// 		const querySnapshot = await getDocs(views_on_day_query);

	// 		dates.push(beginning_date);
	// 		view_counts.push(querySnapshot.size);

	// 		curr_date.setDate(curr_date.getDate() - 1);
	// 	}


	// 	set_graph_views_data_30_days({
	// 		labels: dates,
	// 		datasets: [
	// 			{
	// 				label: "# Views",
	// 				data: view_counts
	// 			}
	// 		]
	// 	})

	// }



	useEffect(() => {
		refresh_redemptions_1_day()
		refresh_redemptions_7_days()
		refresh_redemptions_30_days()

		refresh_views_1_day()
		refresh_views_7_days()
		refresh_views_30_days()
	}, [])

	

	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero min-h-screen bg-base-200">
			<div>
				<div style={{display: "inline", width: "30%", float: "left", margin: "50px"}}>

					<h1 className="text-4xl font-bold mb-3"> Redemptions </h1>

					<h1 style={{fontSize:"25px", marginTop: "45px"}} > Within the last 24 hours: </h1>
						{ num_redemptions_1_day }

					<h1 style={{fontSize:"25px", marginTop: "45px"}} > Within the last 7 days: </h1>
						{ num_redemptions_7_days }

					<h1 style={{fontSize:"25px", marginTop: "45px"}} > Within the last 30 days: </h1>
						{ num_redemptions_30_days }

				</div>


				<div style={{display: "inline", width: "30%", float: "left", margin: "50px"}}>

					<h1 className="text-4xl font-bold mb-3"> Page Views </h1>

					<h1 style={{fontSize:"25px", marginTop: "45px"}} > Within the last 24 hours: </h1>
						{ num_views_1_day }

					<h1 style={{fontSize:"25px", marginTop: "45px"}} > Within the last 7 days: </h1>
						{ num_views_7_days }

					<h1 style={{fontSize:"25px", marginTop: "45px"}} > Within the last 30 days: </h1>
						{ num_views_30_days }

				</div>

				

			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
				<li><a onClick={navigate_to_UpdateDeal}>{change_one_time_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateRecurringDeal}>{change_recurring_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
				<li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
				<li><a  className = "active"> Redemptions & Views </a></li>
				<li><a onClick={navigate_to_ViewProfile}>View Profile</a></li>
				<li><a onClick={navigate_to_Billing}>Billing</a></li>
				<li><a onClick={navigate_to_FAQs}>FAQs</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}

// chart js make line graph: https://www.educative.io/answers/how-to-create-a-react-chartjs-line-graph-app


export default ViewRedemptions;
