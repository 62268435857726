import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';


export function ViewProfile(props) {

	const navigate = useNavigate()

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal")
	}

	const navigate_to_UpdateRecurringDeal = () => {
	    navigate("/update_recurring_deal")
	}

	const navigate_to_ActivateDeactivateDeal = () => {
		navigate("/activate_deactivate_deal")
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_Billing = () => {
		navigate("/billing");
	}

	const navigate_to_FAQs = () => {
	    navigate("/frequently_asked_questions");
	}
	
    const currentUser = auth.currentUser.uid
 
	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")

	const [monday_start_time, set_monday_start_time] = useState("");
	const [monday_end_time, set_monday_end_time] = useState("");
	const [tuesday_start_time, set_tuesday_start_time] = useState("");
	const [tuesday_end_time, set_tuesday_end_time] = useState("");
	const [wednesday_start_time, set_wednesday_start_time] = useState("");
	const [wednesday_end_time, set_wednesday_end_time] = useState("");
	const [thursday_start_time, set_thursday_start_time] = useState("");
	const [thursday_end_time, set_thursday_end_time] = useState("");
	const [friday_start_time, set_friday_start_time] = useState("");
	const [friday_end_time, set_friday_end_time] = useState("");
	const [saturday_start_time, set_saturday_start_time] = useState("");
	const [saturday_end_time, set_saturday_end_time] = useState("");
	const [sunday_start_time, set_sunday_start_time] = useState("");
	const [sunday_end_time, set_sunday_end_time] = useState("");

	const [update_hours_of_operation_button_variant, set_update_hours_of_operation_button_variant] = useState("outlined");
	const [update_hours_of_operation_button_disabled, set_update_hours_of_operation_button_disabled] = useState(true);
	const [updated_hours_of_operation_saved_text, set_updated_hours_of_operation_saved_text] = useState("");

	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
	  const docRef = doc(db, "businesses", currentUser)
	  const docSnap = await getDoc(docRef)

	  if (docSnap.exists()) {
	      if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
	          set_change_one_time_deal_button_text("Create One-Time Deal")
	      } else {
	          set_change_one_time_deal_button_text("Update One-Time Deal")
	      }

	      if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
	          set_change_recurring_deal_button_text("Create Deal for Returning Customers")
	      } else {
	          set_change_recurring_deal_button_text("Update Deal for Returning Customers")
	      }

	      if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
	          set_change_images_button_text("Upload Images")
	      } else {
	          set_change_images_button_text("Update Images")
	      }


	  }
	}

	const [profile_data, set_profile_data] = useState("Loading... ");

	const refresh_profile_data = async () => {
		const docRef = doc(db, "businesses", currentUser)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			if (docSnap.data()["hours_of_operation"] !== undefined) {
				var hoo = docSnap.data()["hours_of_operation"]

				console.log("TIME TIME TIME", hoo)

				set_monday_start_time(hoo["monday_start_time"]);
				set_monday_end_time(hoo["monday_end_time"]);
				set_tuesday_start_time(hoo["tuesday_start_time"]);
				set_tuesday_end_time(hoo["tuesday_end_time"]);
				set_wednesday_start_time(hoo["wednesday_start_time"]);
				set_wednesday_end_time(hoo["wednesday_end_time"]);
				set_thursday_start_time(hoo["thursday_start_time"]);
				set_thursday_end_time(hoo["thursday_end_time"]);
				set_friday_start_time(hoo["friday_start_time"]);
				set_friday_end_time(hoo["friday_end_time"]);
				set_saturday_start_time(hoo["saturday_start_time"]);
				set_saturday_end_time(hoo["saturday_end_time"]);
				set_sunday_start_time(hoo["sunday_start_time"]);
				set_sunday_end_time(hoo["sunday_end_time"]);
			}

			if (docSnap.data()["user_first_name"] !== undefined 
					&& docSnap.data()["user_last_name"] !== undefined
				    && docSnap.data()["user_email"] !== undefined 
				    && docSnap.data()["business_name"] !== undefined
				    && docSnap.data()["business_category"] !== undefined
				    && docSnap.data()["business_address_street_address"] !== undefined
				    && docSnap.data()["business_address_city"] !== undefined
				    && docSnap.data()["business_address_state"] !== undefined
				    && docSnap.data()["business_address_zip_code"] !== undefined ) {
				const first_name = docSnap.data()["user_first_name"];
				const last_name = docSnap.data()["user_last_name"];
				const email = docSnap.data()["user_email"];
				const business_name = docSnap.data()["business_name"];
				const business_category = docSnap.data()["business_category"];
				const street_address = docSnap.data()["business_address_street_address"];
				const city = docSnap.data()["business_address_city"];
				const state = docSnap.data()["business_address_state"];
				const zip_code = docSnap.data()["business_address_zip_code"];

				set_profile_data(
					<div> 
						<b><h2 style={{marginTop:"15px"}}> First Name: </h2></b> 
							<h2> {first_name} </h2>
						<b><h2 style={{marginTop:"15px"}}> Last Name: </h2></b> 
							<h2> {last_name} </h2>
						<b><h2 style={{marginTop:"15px"}}> Email: </h2></b> 
							<h2> {email} </h2>
						<b><h2 style={{marginTop:"15px"}}> Business Name: </h2></b> 
							<h2> {business_name} </h2>
						<b><h2 style={{marginTop:"15px"}}> Business Category: </h2></b> 
							<h2> {business_category} </h2>
						<b><h2 style={{marginTop:"15px"}}> Business Street Address: </h2></b> 
							<h2> {street_address} </h2>
						<b><h2 style={{marginTop:"15px"}}> City: </h2></b> 
							<h2> {city} </h2>
						<b><h2 style={{marginTop:"15px"}}> State: </h2></b> 
							<h2> {state} </h2>
						<b><h2 style={{marginTop:"15px"}}> Zip Code: </h2></b> 
							<h2> {zip_code} </h2>

						
					</div>
				)
			} 
		} 
	}

	const submit_updated_hours_of_operation = async () => {
		var updated_hours_of_operation = {
			"monday_start_time": monday_start_time,
			"monday_end_time": monday_end_time,

			"tuesday_start_time": tuesday_start_time,
			"tuesday_end_time": tuesday_end_time,

			"wednesday_start_time": wednesday_start_time,
			"wednesday_end_time": wednesday_end_time,

			"thursday_start_time": thursday_start_time,
			"thursday_end_time": thursday_end_time,

			"friday_start_time": friday_start_time,
			"friday_end_time": friday_end_time,

			"saturday_start_time": saturday_start_time,
			"saturday_end_time": saturday_end_time,

			"sunday_start_time": sunday_start_time,
			"sunday_end_time": sunday_end_time,
		}

		console.log("\n\n\n\nUPDATING TIME ", updated_hours_of_operation)

		const docRef = doc(db, "businesses", currentUser)
        await setDoc(docRef, { 
        	hours_of_operation: updated_hours_of_operation,
        }, { merge: true })

        set_updated_hours_of_operation_saved_text("Success! Your hours of operation have been updated. ");
	}

	const update_time_state = async (event, f) => {
		f(event.target.value);
		set_update_hours_of_operation_button_variant("contained");
		set_update_hours_of_operation_button_disabled(false);
	}

	useEffect(() => {
		refresh_profile_data()
	}, [])

	



	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero min-h-screen bg-base-200">
			<div>
				<h1 className="text-4xl font-bold mb-3" style={{textAlign: "center"}}> My Profile </h1>

				<div style={{display: "flex"}}>
					<div id="basic_profile_data_div" style={{flex:"1", marginRight: "60px"}}>
							{ profile_data }
					</div>

					<div id="hours_of_operation_div" style={{flex:"1"}}>
						<b><h2 style={{marginTop:"15px"}}> Current Hours of Operation: </h2></b> 
						{updated_hours_of_operation_saved_text}
						<b><h2 style={{marginTop:"15px"}}> Monday: </h2></b> 
						<div display="inline"> 
							<input type="time" default="12:00"
								   value={monday_start_time}
								   onChange={(e) => {update_time_state(e, set_monday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={monday_end_time}
								   onChange={(e) => {update_time_state(e, set_monday_end_time)}} />
						</div>

						<b><h2 style={{marginTop:"15px"}}> Tuesday: </h2></b> 
						<div display="inline"> 
							<input type="time"
								   value={tuesday_start_time}
								   onChange={(e) => {update_time_state(e, set_tuesday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={tuesday_end_time}
								   onChange={(e) => {update_time_state(e, set_tuesday_end_time)}} />
						</div>

						<b><h2 style={{marginTop:"15px"}}> Wednesday: </h2></b> 
						<div display="inline"> 
							<input type="time"
								   value={wednesday_start_time}
								   onChange={(e) => {update_time_state(e, set_wednesday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={wednesday_end_time}
								   onChange={(e) => {update_time_state(e, set_wednesday_end_time)}} />
						</div>

						<b><h2 style={{marginTop:"15px"}}> Thursday: </h2></b> 
						<div display="inline"> 
							<input type="time"
								   value={thursday_start_time}
								   onChange={(e) => {update_time_state(e, set_thursday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={thursday_end_time}
								   onChange={(e) => {update_time_state(e, set_thursday_end_time)}} />
						</div>

						<b><h2 style={{marginTop:"15px"}}> Friday: </h2></b> 
						<div display="inline"> 
							<input type="time"
								   value={friday_start_time}
								   onChange={(e) => {update_time_state(e, set_friday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={friday_end_time}
								   onChange={(e) => {update_time_state(e, set_friday_end_time)}} />
						</div>

						<b><h2 style={{marginTop:"15px"}}> Saturday: </h2></b> 
						<div display="inline"> 
							<input type="time"
								   value={saturday_start_time}
								   onChange={(e) => {update_time_state(e, set_saturday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={saturday_end_time}
								   onChange={(e) => {update_time_state(e, set_saturday_end_time)}} />
						</div>

						<b><h2 style={{marginTop:"15px"}}> Sunday: </h2></b> 
						<div display="inline"> 
							<input type="time"
								   value={sunday_start_time}
								   onChange={(e) => {update_time_state(e, set_sunday_start_time)}}
								   style={{display: "inline"}} />
							
							<p style={{display: "inline"}}> to </p> 

							<input type="time"
								   value={sunday_end_time}
								   onChange={(e) => {update_time_state(e, set_sunday_end_time)}} />
						</div>

						<Button variant={update_hours_of_operation_button_variant}
								disabled={update_hours_of_operation_button_disabled}
								style={{marginTop: "10px", fontSize:"12px", padding:"3px"}}
						        onClick={() => {submit_updated_hours_of_operation()}}> Update Hours of Operation </Button>
					</div>
				


				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
				<li><a onClick={navigate_to_UpdateDeal}>{change_one_time_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateRecurringDeal}>{change_recurring_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
				<li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
				<li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
				<li><a  className = "active">View My Profile</a></li>
				<li><a onClick={navigate_to_Billing}>Billing</a></li>
				<li><a onClick={navigate_to_FAQs}>FAQs</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default ViewProfile;
