import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import "./UpdateImages.css"
import { useNavigate, useLocation } from "react-router-dom";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';



export function UpdateImages(props) {
	const navigate = useNavigate()

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal");
	}
  
	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_ViewProfile = () => {
		navigate("/view_profile");
	}

	const navigate_to_UpdateRecurringDeal = () => {
	    navigate("/update_recurring_deal")
	}

	const navigate_to_ActivateDeactivateDeal = () => {
		navigate("/activate_deactivate_deal")
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_Billing = () => {
	    navigate("/billing");
	}

	const navigate_to_FAQs = () => {
	    navigate("/frequently_asked_questions");
	}
	
    const currentUser = auth.currentUser.uid

	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")


	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
	  const docRef = doc(db, "businesses", currentUser)
	  const docSnap = await getDoc(docRef)

	  if (docSnap.exists()) {
	      if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
	          set_change_one_time_deal_button_text("Create One-Time Deal")
	      } else {
	          set_change_one_time_deal_button_text("Update One-Time Deal")
	      }

	      if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
	          set_change_recurring_deal_button_text("Create Deal for Returning Customers")
	      } else {
	          set_change_recurring_deal_button_text("Update Deal for Returning Customers")
	      }

	      if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
	          set_change_images_button_text("Upload Images")
	      } else {
	          set_change_images_button_text("Update Images")
	      }


	  }
	}    

	const [images_display_array, set_images_display_array] = useState([]); 
	const [images_files, set_images_files] = useState([]);
	const [updated_images_status_message, set_updated_images_status_message] = useState([]);
	const [update_images_button_disabled, set_update_images_button_disabled] = useState(false);
	const [current_images, set_current_images] = useState([])

	const refresh_current_images_content = async () => {
		const docRef = doc(db, "businesses", currentUser)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			if (docSnap.data()["images"] !== undefined && docSnap.data()["images"].length > 0) {
				const images_list_download_URLs = docSnap.data()["images"]; 
				const images_content = [];

				images_list_download_URLs.forEach((im) => {
					images_content.push(
						<img src={im} 
							 alt="image"
							 width="300px"
							 style={{borderRadius: "15px", display:"inline" , margin: "10px"}} />
					)
				})

				set_current_images(images_content)
			} else {
				set_current_images(
					<h3> You do not have any images for your business- please use the Update Images section below to add some! </h3>
				)
			}
		} 
	}

	useEffect(() => {
		refresh_current_images_content()
	}, [])

	const delete_image = (index) => {
		images_files.splice(index, 1)
		set_images_files([...images_files])
	}

	useEffect(() => {
		set_images_display_array([])
		for (let i = 0; i < images_files.length; i++) {
			set_images_display_array(previous_images_array => [...previous_images_array, 
									                   <div className="image">
									                       <img src={URL.createObjectURL(images_files[i])}
									                       		alt="image"/>
									                       	<span onClick={() => {delete_image(i)}}> X </span>
									                   </div> ])
		}
	}, [images_files])

	const new_image_uploaded = (event) => {
		const files = event.target.files;
		const index = images_files.length

		for (let i = 0; i < files.length; i++) {
			set_images_files(previous_images_files => [...previous_images_files, files[i]])
		}
	}

	async function uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            lst.push(file)
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            lst.push(downloadURL)
        })
    }

    async function submit_updated_images() {
    	set_update_images_button_disabled(true)
    	set_updated_images_status_message([])
		const business_images_URLS = []

		for (var i = 0; i < images_files.length; i++) {
			const image = images_files[i]
			await uploadImage(image, business_images_URLS)
		}
		
		const docRef = doc(db, 'businesses', currentUser);
        await setDoc(docRef, {
            images: business_images_URLS
        }, { merge: true })
        	.then(() => {
				set_updated_images_status_message([
	                <Alert
	                    severity="success"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_updated_images_status_message([])}}>
	                        	CLOSE
	                        </Button>
	                    }
	                    >
	                    Your images have been updated!
	                </Alert>
	            ])
			})
			.catch((error) => {
				set_updated_images_status_message([
	                <Alert
	                    severity="success"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_updated_images_status_message([])}}>
	                        CLOSE
	                        </Button>
	                    }
	                    >
	                    {error.code}
	                </Alert>
	            ])
			});

			set_update_images_button_disabled(false)
			refresh_current_images_content()
			set_images_files([])
	}

	

	return (
		<>
			<div className="drawer drawer-mobile drawer-sm">
				<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
				<div className="drawer-content flex flex-col items-center justify-center">
				<div className="hero min-h-screen bg-base-200">
					<div>
						{updated_images_status_message}

						<h1 className="text-4xl font-bold mb-3"> My Current Images </h1>
						{ current_images }

						<h1 className="text-4xl font-bold mb-3"
						    style={{marginTop: "200px"}}>Update Images</h1>

						<h1 style={{marginTop: "50px"}}> Choose between 1-10 images.  </h1>
						<input type="file" multiple="multiple" className="file-input w-full max-w-xs" accept="image\/jpeg, image\/jpg, image\/png" onChange={new_image_uploaded} />
						<output> {images_display_array} </output>
						<button className="btn btn-primary" 
								type="button" 
								onClick={submit_updated_images}
								disabled={update_images_button_disabled}> Publish Images </button>
					</div>
				</div>
				</div> 
				<div className="drawer-side">
				<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
				<ul className="menu p-4 w-80 bg-base-100 text-base-content">
					<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
					<li><a onClick={navigate_to_UpdateDeal}>{change_one_time_deal_button_text}l</a></li>
					<li><a onClick={navigate_to_UpdateRecurringDeal}>{change_recurring_deal_button_text}</a></li>
					<li><a className="active" onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
					<li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
					<li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
					<li><a onClick={navigate_to_ViewProfile}>View My Profile</a></li>
					<li><a onClick={navigate_to_Billing}>Billing</a></li>
					<li><a onClick={navigate_to_FAQs}>FAQs</a></li>
				</ul>
				
				</div>
			</div>
		</>
	);
}




export default UpdateImages;
