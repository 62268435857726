import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';


export function UpdateRecurringDeal(props) {

	const navigate = useNavigate()

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_ViewProfile = () => {
		navigate("/view_profile");
	}

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal")
	}

	const navigate_to_ActivateDeactivateDeal = () => {
		navigate("/activate_deactivate_deal")
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_Billing = () => {
		navigate("/billing");
	}

	const navigate_to_FAQs = () => {
	    navigate("/frequently_asked_questions");
	}
	
    const currentUser = auth.currentUser.uid

	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")


	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
	  const docRef = doc(db, "businesses", currentUser)
	  const docSnap = await getDoc(docRef)

	  if (docSnap.exists()) {
	      if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
	          set_change_one_time_deal_button_text("Create One-Time Deal")
	      } else {
	          set_change_one_time_deal_button_text("Update One-Time Deal")
	      }

	      if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
	          set_change_recurring_deal_button_text("Create Deal for Returning Customers")
	      } else {
	          set_change_recurring_deal_button_text("Update Deal for Returning Customers")
	      }

	      if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
	          set_change_images_button_text("Upload Images")
	      } else {
	          set_change_images_button_text("Update Images")
	      }


	  }
	}

	const [recurring_deal_desc, set_recurring_deal_desc] = useState(""); 
	const [recurring_deal_restrictions_conditions, set_recurring_deal_restrictions_conditions] = useState("")
	const [image_file, set_image_file] = useState([]);
	const [image_display, set_image_display] = useState([]);
	const [updated_deal_status_message, set_updated_deal_status_message] = useState([]);
	const [update_deal_button_disabled, set_update_deal_button_disabled] = useState(false);
	const [current_deal, set_current_deal] = useState("Loading... ");

	const refresh_current_deal_content = async () => {
		const docRef = doc(db, "businesses", currentUser)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			if (docSnap.data()["recurring_deal_desc"] !== undefined 
					&& docSnap.data()["recurring_deal_restrictions_conditions"] !== undefined
				    && docSnap.data()["recurring_deal_image"] !== undefined) {
				const recurring_deal_description = docSnap.data()["recurring_deal_desc"];
				const recurring_deal_restrictions_conditions = docSnap.data()["recurring_deal_restrictions_conditions"]
				const recurring_deal_image_download_URL = docSnap.data()["recurring_deal_image"];
				set_current_deal(
					<div> 
						<b><h2 style={{marginTop:"15px"}}> Title: </h2></b> 
							<h2> {recurring_deal_description} </h2>
						<b><h2 style={{marginTop:"15px"}}> Restrictions & Conditions: </h2></b> 
							<h2> {recurring_deal_restrictions_conditions} </h2>
						<b><h2 style={{marginTop:"15px"}}> Image: </h2></b> 
							<img src={recurring_deal_image_download_URL} 
								 alt="image"
								 width="300px" 
								 style={{borderRadius: "15px", marginBottom: "100px"}} />
					</div>
				)
			} else if (docSnap.data()["recurring_deal_desc"] !== undefined) {
				const recurring_deal_description = docSnap.data()["recurring_deal_desc"];
				set_current_deal(
					<div> 
						<b><h2> Title: </h2></b> 
							<h2> {recurring_deal_description} </h2>
					</div>
				)
			} else {
				set_current_deal(
					<h3> You do not have a currently active deal- please use the Update Deal Description & Image section below to create one! </h3>
				)
			}
		} 
	}

	useEffect(() => {
		refresh_current_deal_content()
	}, [])


	const change_deal_desc = (event) => {
		set_recurring_deal_desc(event.target.value);
	}

	const change_deal_restrictions_conditions = (event) => {
		set_recurring_deal_restrictions_conditions(event.target.value)
	}

	async function uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            lst.push(file)
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            lst.push(downloadURL)
        })
    }

	async function submit_deal_update() {
		set_update_deal_button_disabled(true)
    	set_updated_deal_status_message([])

		const deal_image_URL = []

		for (var i = 0; i < image_file.length; i++) {
			const image = image_file[i]
			await uploadImage(image, deal_image_URL)
		}

		const image_URL = deal_image_URL[0]
		const docRef = doc(db, "businesses", currentUser)
        await setDoc(docRef, { 
        	recurring_deal_desc: recurring_deal_desc,
        	recurring_deal_restrictions_conditions: recurring_deal_restrictions_conditions,
        	recurring_deal_image: image_URL
        }, { merge: true })
        	.then(() => {
				set_updated_deal_status_message([
	                <Alert
	                    severity="success"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_updated_deal_status_message([])}}>
	                        	CLOSE
	                        </Button>
	                    }
	                    >
	                    Your recurring deal has been updated!
	                </Alert>
	            ])
			})
			.catch((error) => {
				set_updated_deal_status_message([
	                <Alert
	                    severity="success"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_updated_deal_status_message([])}}>
	                        CLOSE
	                        </Button>
	                    }
	                    >
	                    {error.code}
	                </Alert>
	            ])
			});

			set_update_deal_button_disabled(false)
			set_recurring_deal_desc("")
			set_recurring_deal_restrictions_conditions("")
			set_image_file([])
			refresh_current_deal_content()
	}

	const new_image_uploaded = (event) => {
		set_image_file([event.target.files[0]]);		
	}


	useEffect(() => {
		set_image_display([]);
		if (image_file.length <= 0) {return}
		set_image_display([ <div className="image">
				                       <img src={URL.createObjectURL(image_file[0])}
				                       		alt="image"/>
				                       	<span onClick={() => {set_image_file([])}}> X </span>
				                   </div> ])
	}, [image_file])


	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200">
			<div>
				<div>
					{updated_deal_status_message}

					<h1 className="text-4xl font-bold mb-3" style={{marginTop:"50px"}}> My Current Deal for Returning Customers </h1>
						{ current_deal }

					<hr></hr>


					<h1 className="text-4xl font-bold mb-3"
						style={{marginTop: "20px"}}> Update Deal Description & Image </h1>
					<h1> *We recommend a deal of 10% off or better for returning customers. </h1>

					<h1 style={{marginTop: "50px"}}> Set the Description </h1>
					<input type="text" 
					       className="input max-w-xs" 
					       onChange={change_deal_desc} 
					       placeholder="Enter Deal Description" />

					<h1 style={{marginTop: "50px"}}> Set the Restrictions & Conditions </h1>
					<input type="text" 
					       className="input max-w-xs" 
					       onChange={change_deal_restrictions_conditions} 
					       placeholder="Restrictions & Conditions" />

					<h1 style={{marginTop: "50px"}}> Choose an Image </h1>
					<input type="file" 
						   className="file-input w-full max-w-xs" 
						   accept="image\/jpeg, image\/jpg, image\/png" 
						   onChange={new_image_uploaded} 
						   style={{display: "block"}} />
					<output style={{marginBottom: "0px"}}> {image_display} </output>

					<button 
						type="button" 
						className="btn btn-" 
						onClick={submit_deal_update}
						disabled={update_deal_button_disabled}
						style={{marginBottom: "200px"}}> Publish Deal </button>
				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
				<li><a onClick={navigate_to_UpdateDeal}> {change_one_time_deal_button_text} </a></li>
				<li><a  className = "active">{change_recurring_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
				<li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
				<li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
				<li><a onClick={navigate_to_ViewProfile}>View My Profile</a></li>
				<li><a onClick={navigate_to_Billing}>Billing</a></li>
				<li><a onClick={navigate_to_FAQs}>FAQs</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default UpdateRecurringDeal;
