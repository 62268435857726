
import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc, and, or, getCountFromServer } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert, FormControl, InputLabel, Select, MenuItem, Modal, Box } from '@mui/material';



export function AdminViewMetrics(props) {

	const navigate = useNavigate()
    const currentUser = auth.currentUser.uid

    const [metrics_details, set_metrics_details] = useState([])
    const [redemptions_table_data, set_redemptions_table_data] = useState([]);
    const [num_days_selected, set_num_days_selected] = useState(7);

    const [table_timeline_content, set_table_timeline_content] = useState([]);
    const [table_timeline_header_dates, set_table_timeline_header_dates] = useState([]);

    const [table_timeline_modal_open, set_table_timeline_modal_open] = useState(false);
    const [table_timeline_modal_content, set_table_timeline_modal_content] = useState([]);


    const navigate_to_NewBusinessApproval = () => {
    	navigate("/dealio_admin")
    }
  	const navigate_to_AdminBilling = () => {
    	navigate("/admin_billing")
    }
    const navigate_to_ViewAllBusinesses = () => {
    	navigate("/admin_view_all_businesses")
    }
    const navigate_to_UpdateBusinessInfo = () => {
    	navigate("/admin_update_business_info")
    }
    const navigate_to_AdminViewMetrics = () => {
    	navigate("/admin_view_metrics")
    }


    const refresh_redemptions_table_for_redemptions_within_x_days = async (num_days) => {
    	var redemptions_data;

    	if (num_days == -1) {
    		const query_redemptions_all_time = query(collection(db, "redemptions"));
    		redemptions_data = await getDocs(query_redemptions_all_time);
    	} else {
    		var curr_date = new Date();
    		curr_date.setDate(curr_date.getDate() - num_days);

			const query_redemptions_num_days = query(collection(db, "redemptions"), and(
				where('time', '>=', curr_date)
			));
			redemptions_data = await getDocs(query_redemptions_num_days);
    	}

    	var new_redemptions_table_rows = [];
 
    	redemptions_data.forEach(async (doc) => {
    		var redemption_date = doc.data()["time"].toDate().toLocaleDateString();
    		var redemption_time = doc.data()["time"].toDate().toLocaleTimeString();
    		
    		new_redemptions_table_rows.push(
    			<tr>
					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> {doc.data()["business_name"]} </td>
					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> {doc.data()["deal_name"]} </td>
					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> {redemption_date + " " + redemption_time} </td>
				</tr>
    		);
    	});

    	set_redemptions_table_data(new_redemptions_table_rows);
    }

    function difference_in_days(start, end) {  
	    return Math.round((end - start) / (1000 * 60 * 60 * 24));   
	}
    const get_all_business_IDs = async () => {
    	const all_approved_businesses = query(collection(db, "businesses"), and(
			where('approved', '==', "true")
		));
		var query_result = await getDocs(all_approved_businesses);
		var output = [];
		query_result.forEach(async (doc) => {
			output.push(doc.id);
		});
		return output;
    }

    const handle_table_timeline_modal_open = () => {
		set_table_timeline_modal_open(true);
    } 
    const handle_table_timeline_modal_closed = () => {
    	set_table_timeline_modal_open(false);
    }

    const handle_table_timeline_cell_clicked = async (business_ID, query_data) => {
    	if (query_data === "company name column... ") {
    		return;
    	}

    	await set_table_timeline_modal_content([]);
    	handle_table_timeline_modal_open();

    	query_data.forEach(async (doc) => {
    		var redemption_date = doc.data()["time"].toDate().toLocaleDateString();
    		var redemption_time = doc.data()["time"].toDate().toLocaleTimeString();
    		
    		set_table_timeline_modal_content((old) => [...old,
	    		<tr>
					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> {doc.data()["business_name"]} </td>
					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> {doc.data()["deal_name"]} </td>
					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> {redemption_date + " " + redemption_time} </td>
				</tr>
	    	] );
    	});


    }

    const refresh_redemptions_table_timeline = async () => {
    	await set_table_timeline_content([]);
    	await set_table_timeline_header_dates([]);
    	var business_IDs = await get_all_business_IDs();
    	var num_days = num_days_selected;
    	if (num_days_selected === -1) {
    		num_days = difference_in_days(new Date(2023, 8-1, 1), new Date());
    	} 

    	var dates_table_header_list = ["Company"];
    	for (var i = 0; i < num_days; i++) {
			// get ending date and beginning date for 1-day period
			var curr_date = new Date();
			curr_date.setDate(curr_date.getDate() - i);
			var month = curr_date.getMonth() + 1;
			var day_of_month = curr_date.getDate();
			var year = curr_date.getYear() + 1900;
			var ending_date = new Date(year, month-1, day_of_month);
			
			dates_table_header_list.push(ending_date.toLocaleDateString());
		}
		set_table_timeline_header_dates([
			<tr>
				{dates_table_header_list.map((dat, idx) => (
					<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> 
						{dat} 
					</th>
				))}
			</tr>
		]);

    	var table_rows_list = [];
    	business_IDs.forEach(async (biz_id) => {
    		var table_data_list_for_biz_id = [];
  
			const docRef = doc(db, "businesses", biz_id);
			const docSnap = await getDoc(docRef);
			table_data_list_for_biz_id.push([biz_id, docSnap.data()["business_name"], "company name column... "]);

    		for (var i = 0; i < num_days; i++) {
    			// get ending date and beginning date for 1-day period
    			var curr_date = new Date();
    			curr_date.setDate(curr_date.getDate() - i);

    			var month = curr_date.getMonth() + 1;
				var day_of_month = curr_date.getDate();
				var year = curr_date.getYear() + 1900;

				var ending_date = new Date(year, month-1, day_of_month);
				ending_date.setHours(23, 59, 59, 999);
				var beginning_date = new Date(year, month-1, day_of_month);
				beginning_date.setHours(0, 0, 0, 0);

				const q = query(collection(db, "redemptions"), and(
						where("business_id", "==", biz_id),
						where('time', '>=', beginning_date),
						where('time', '<=', ending_date),	
					));
				const querySnapshot = await getDocs(q);

				table_data_list_for_biz_id.push([biz_id, querySnapshot.size, querySnapshot]);

    		}

    		set_table_timeline_content((old) => [...old, 
    			<tr>
    				{table_data_list_for_biz_id.map((dat, idx) => (
    					<td style={{border: "1px solid black", textAlign: "center", padding: "10px"}}
    						onClick={() => {handle_table_timeline_cell_clicked(dat[0], dat[2])}}> 
    						{dat[1]} 
    					</td>
    				))}
    			</tr>
    		])
    	})
    	
    }



	
	const refresh_metrics_details = async () => {
		// number of businesses
		const businesses_collection = collection(db, "businesses");
		const query_businesses = query(businesses_collection, where("approved", "==", "true"));
		const snapshot_businesses = await getCountFromServer(query_businesses);
		const total_approved_businesses = snapshot_businesses.data().count;

		// number of views
		const views_collection = collection(db, "views");
		const snapshot_views = await getCountFromServer(views_collection);
		const total_views = snapshot_views.data().count;

		// number of redemptions
		const redemptions_collection = collection(db, "redemptions");
		const snapshot_redemptions = await getCountFromServer(redemptions_collection);
		const total_redemptions = snapshot_redemptions.data().count;

		// TDOO: set up table with each row containing details about 1 redemption. 
		// TODO: add dropdown with options for viewing redemptions in last 7 days, 30 days, all time
		// exmaple: https://react-bootstrap.netlify.app/docs/components/dropdowns/

		set_metrics_details([
				<div>
					<b><h2 style={{marginTop:"15px", fontSize:"25px"}}> Total Businesses: </h2></b> 
						<h2> {total_approved_businesses} </h2>

					<b><h2 style={{marginTop:"15px", fontSize:"25px"}}> Total Redemptions: </h2></b> 
						<h2> {total_redemptions} </h2>

					<b><h2 style={{marginTop:"15px", fontSize:"25px"}}> Total Business Page Views: </h2></b> 
						<h2> {total_views} </h2>
				</div>
			]);
	}

	useEffect(() => {
		refresh_metrics_details()
	}, [])

	useEffect(() => {
		refresh_redemptions_table_for_redemptions_within_x_days(num_days_selected);
		refresh_redemptions_table_timeline();
	}, [num_days_selected])



	if (currentUser !== process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
		return (
			<div>
				<h1> You do not have access to this page. </h1>
			</div>
		)
	}

	const modal_styling = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200" style={{overflowX: "auto", padding: "10px"}}>
				<div>
					<h1 className="text-4xl font-bold mb-3"> Admin: View Metrics </h1>
					{metrics_details}


					<b><h2 style={{marginTop:"100px", fontSize:"25px"}}> Redemptions Table </h2></b> 

					<FormControl style={{width: "220px"}}>
					  <InputLabel> # Days </InputLabel>
					  <Select
					    value={num_days_selected}
					    label="# Days"
					    onChange={(event) => {set_num_days_selected(event.target.value)}} >
					    <MenuItem value={1}> 1 </MenuItem>
					    <MenuItem value={7}> 7 </MenuItem>
					    <MenuItem value={30}> 30 </MenuItem>
					    <MenuItem value={90}> 90 </MenuItem>
					    <MenuItem value={-1}> All Time </MenuItem>
					  </Select>
					</FormControl>

					<b><h2 style={{marginTop:"10px", fontSize:"20px"}}> All Redemptions Data </h2></b> 
					<table style={{margin: "12px"}}>
						<tr>
							<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> Business Name </th>
							<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> Deal Name </th>
							<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> Time </th>
						</tr>

						{redemptions_table_data}
					</table>	

					<b><h2 style={{marginTop:"10px", fontSize:"20px"}}> Redemptions by Date & Business (Click on cell for more details) </h2></b> 
					<table style={{margin: "12px"}}> 
						{table_timeline_header_dates}
						{table_timeline_content}
					</table>		


					<Modal open={table_timeline_modal_open}
						   onClose={handle_table_timeline_modal_closed}>
						   <Box sx={modal_styling}>
						   	    <table>
									<tr>
										<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> Business </th>
										<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> Deal Name </th>
										<th style={{border: "1px solid black", textAlign: "center", padding: "10px"}}> Time </th>
									</tr>
									{table_timeline_modal_content}
							    </table>
						  </Box>
					</Modal>
				</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_NewBusinessApproval}>New Business Approval</a></li>
				<li><a onClick={navigate_to_AdminBilling}>Admin Billing</a></li>
				<li><a onClick={navigate_to_ViewAllBusinesses}>View All Businesses</a></li>
				<li><a onClick={navigate_to_UpdateBusinessInfo}>Update Business Info</a></li>
				<li><a className = "active"> View Metrics </a></li>

			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default AdminViewMetrics;
