import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert, Switch, FormControlLabel } from '@mui/material';



export function ActivateDeactivateDeal(props) {

	const navigate = useNavigate()

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal");
	}

	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_ViewProfile = () => {
		navigate("/view_profile");
	}

	const navigate_to_UpdateRecurringDeal = () => {
		navigate("/update_recurring_deal");
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_Billing = () => {
		navigate("/billing");
	}

	const navigate_to_FAQs = () => {
	    navigate("/frequently_asked_questions");
	}
	
    const currentUser = auth.currentUser.uid

	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")


	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
	  const docRef = doc(db, "businesses", currentUser)
	  const docSnap = await getDoc(docRef)

	  if (docSnap.exists()) {
	      if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
	          set_change_one_time_deal_button_text("Create One-Time Deal")
	      } else {
	          set_change_one_time_deal_button_text("Update One-Time Deal")
	      }

	      if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
	          set_change_recurring_deal_button_text("Create Deal for Returning Customers")
	      } else {
	          set_change_recurring_deal_button_text("Update Deal for Returning Customers")
	      }

	      if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
	          set_change_images_button_text("Upload Images")
	      } else {
	          set_change_images_button_text("Update Images")
	      }

	  }
	}

	const [deal_desc, set_deal_desc] = useState(""); 
	const [deal_restrictions_conditions, set_deal_restrictions_conditions] = useState("")
	const [image_file, set_image_file] = useState([]);

	const [updated_preference_status_message, set_updated_preference_status_message] = useState([]);

	const [current_deal, set_current_deal] = useState("Loading... ");
	const [one_time_deal_toggle_text, set_one_time_deal_toggle_text] = useState("Loading... ");
	const [one_time_deal_is_active, set_one_time_deal_is_active] = useState(false);
	const [one_time_deal_toggle_disabled, set_one_time_deal_toggle_disabled] = useState(false);

	const [recurring_deal, set_recurring_deal] = useState("Loading... ");
	const [recurring_deal_toggle_text, set_recurring_deal_toggle_text] = useState("Loading... ");
	const [recurring_deal_is_active, set_recurring_deal_is_active] = useState(false);
	const [recurring_deal_toggle_disabled, set_recurring_deal_toggle_disabled] = useState(false);


	const one_time_deal_toggle_clicked = async () => {
		const new_one_time_deal_is_active = !one_time_deal_is_active;
		set_one_time_deal_is_active(new_one_time_deal_is_active)

		const docRef = doc(db, "businesses", currentUser)
		setDoc(docRef, { 
        	one_time_deal_is_active: new_one_time_deal_is_active,
        }, { merge: true })
        	.then(() => {
        		if (new_one_time_deal_is_active === true) {
        			set_updated_preference_status_message([
		                <Alert
		                    severity="success"
		                    action={
		                        <Button color="inherit" size="small" onClick={() => {set_updated_preference_status_message([])}}>
		                        	CLOSE
		                        </Button>
		                    }
		                    >
		                    Your one-time deal is now activated! Users will be able to see it. 
		                </Alert>
		            ])
        		} else if (new_one_time_deal_is_active === false) {
        			set_updated_preference_status_message([
		                <Alert
		                    severity="success"
		                    action={
		                        <Button color="inherit" size="small" onClick={() => {set_updated_preference_status_message([])}}>
		                        	CLOSE
		                        </Button>
		                    }
		                    >
		                    Your one-time deal has now been inactivated. Users will no longer be able to see it. 
		                </Alert>
		            ])
        		}
				
	            refresh_current_deal_content()
	            refresh_recurring_deal_content()
			})
			.catch((error) => {
				set_updated_preference_status_message([
	                <Alert
	                    severity="success"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_updated_preference_status_message([])}}>
	                        CLOSE
	                        </Button>
	                    }
	                    >
	                    {error.code}
	                </Alert>
	            ])
			});
	}

	const recurring_deal_toggle_clicked = async () => {
		const new_recurring_deal_is_active = !recurring_deal_is_active;
		set_recurring_deal_is_active(new_recurring_deal_is_active)

		const docRef = doc(db, "businesses", currentUser)
		setDoc(docRef, { 
        	recurring_deal_is_active: new_recurring_deal_is_active,
        }, { merge: true })
        	.then(() => {
        		if (new_recurring_deal_is_active === true) {
        			set_updated_preference_status_message([
		                <Alert
		                    severity="success"
		                    action={
		                        <Button color="inherit" size="small" onClick={() => {set_updated_preference_status_message([])}}>
		                        	CLOSE
		                        </Button>
		                    }
		                    >
		                    Your recurring deal is now activated! Users will be able to see it. 
		                </Alert>
		            ])
        		} else if (new_recurring_deal_is_active === false) {
        			set_updated_preference_status_message([
		                <Alert
		                    severity="success"
		                    action={
		                        <Button color="inherit" size="small" onClick={() => {set_updated_preference_status_message([])}}>
		                        	CLOSE
		                        </Button>
		                    }
		                    >
		                    Your recurring deal has now been inactivated. Users will no longer be able to see it. 
		                </Alert>
		            ])
        		}
				

	            refresh_current_deal_content()
	            refresh_recurring_deal_content()
			})
			.catch((error) => {
				set_updated_preference_status_message([
	                <Alert
	                    severity="success"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_updated_preference_status_message([])}}>
	                        CLOSE
	                        </Button>
	                    }
	                    >
	                    {error.code}
	                </Alert>
	            ])
			});
	}


	const refresh_current_deal_content = async () => {
		const docRef = doc(db, "businesses", currentUser)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			if (docSnap.data()["deal_desc"] !== undefined 
					&& docSnap.data()["deal_restrictions_conditions"] !== undefined
				    && docSnap.data()["deal_image"] !== undefined
				    && docSnap.data()["one_time_deal_is_active"] !== undefined ) {
				const deal_description = docSnap.data()["deal_desc"];
				const deal_restrictions_conditions = docSnap.data()["deal_restrictions_conditions"]
				const deal_image_download_URL = docSnap.data()["deal_image"];
				const one_time_deal_is_active = docSnap.data()["one_time_deal_is_active"]

				set_one_time_deal_is_active(one_time_deal_is_active)

				if (one_time_deal_is_active === true) {
					set_one_time_deal_toggle_text("Currently Active")
				} else if (one_time_deal_is_active === false) {
					set_one_time_deal_toggle_text("Currently Inactive")
				}
					
		
				set_current_deal(
					<div> 
						<b><h2 style={{marginTop:"15px"}}> Title: </h2></b> 
							<h2> {deal_description} </h2>
						<b><h2 style={{marginTop:"15px"}}> Restrictions & Conditions: </h2></b> 
							<h2> {deal_restrictions_conditions} </h2>
						<b><h2 style={{marginTop:"15px"}}> Image: </h2></b> 
							<img src={deal_image_download_URL} 
								 alt="image"
								 width="300px" 
								 style={{borderRadius: "15px", marginBottom: "30px"}} />						
					</div>
				)
			} else {
				set_one_time_deal_toggle_disabled(true)
				set_one_time_deal_is_active(false)
				set_one_time_deal_toggle_text("N/A")
				set_current_deal(
					<div> 
						<b><h2 style={{marginTop:"15px"}}> You have not created a One-Time Deal.  </h2></b> 
					</div>
				)
			}
		} 
	}


	const refresh_recurring_deal_content = async () => {
		const docRef = doc(db, "businesses", currentUser)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			if (docSnap.data()["recurring_deal_desc"] !== undefined 
					&& docSnap.data()["recurring_deal_restrictions_conditions"] !== undefined
				    && docSnap.data()["recurring_deal_image"] !== undefined
				    && docSnap.data()["recurring_deal_is_active"] !== undefined ) {
				const recurring_deal_description = docSnap.data()["recurring_deal_desc"];
				const recurring_deal_restrictions_conditions = docSnap.data()["recurring_deal_restrictions_conditions"]
				const recurring_deal_image_download_URL = docSnap.data()["recurring_deal_image"];
				const recurring_deal_is_active = docSnap.data()["recurring_deal_is_active"]

				set_recurring_deal_is_active(recurring_deal_is_active)

				if (recurring_deal_is_active === true) {
					set_recurring_deal_toggle_text("Currently Active")
				} else if (recurring_deal_is_active === false) {
					set_recurring_deal_toggle_text("Currently Inactive")
				}
					
				set_recurring_deal(
					<div> 
						<b><h2 style={{marginTop:"15px"}}> Title: </h2></b> 
							<h2> {recurring_deal_description} </h2>
						<b><h2 style={{marginTop:"15px"}}> Restrictions & Conditions: </h2></b> 
							<h2> {recurring_deal_restrictions_conditions} </h2>
						<b><h2 style={{marginTop:"15px"}}> Image: </h2></b> 
							<img src={recurring_deal_image_download_URL} 
								 alt="image"
								 width="300px" 
								 style={{borderRadius: "15px", marginBottom: "30px"}} />
					</div>
				)
			} else {
				set_recurring_deal_toggle_disabled(true)
				set_recurring_deal_is_active(false)
				set_recurring_deal_toggle_text("N/A")
				set_recurring_deal(
					<div> 
						<b><h2 style={{marginTop:"15px"}}> You have not created a Recurring Deal.  </h2></b> 
					</div>
				)
			}
		} 
	}



	useEffect(() => {
		refresh_current_deal_content()
		refresh_recurring_deal_content()
	}, [])



	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero min-h-screen bg-base-200">
			<div>
				<div>
					{updated_preference_status_message}

					<h1 className="text-4xl font-bold mb-3"> Activate or Deactivate Your Deals </h1>

					<b><h1 style={{marginTop: "50px", fontSize: "23px"}}> My Current One-Time Deal: </h1></b>
					{current_deal}
					<b><h1 style={{marginTop: "20px", fontSize: "23px"}}> Status: </h1></b>
					<FormControlLabel control={
							<Switch checked={one_time_deal_is_active}
								disabled={one_time_deal_toggle_disabled}
						        onChange={one_time_deal_toggle_clicked}> </Switch> 
						} label={one_time_deal_toggle_text} />


					<b><h1 style={{marginTop: "50px", fontSize: "23px"}}> My Current Recurring Deal: </h1></b>
					{recurring_deal}
					<b><h1 style={{marginTop: "20px", fontSize: "23px"}}> Status: </h1></b>
					<FormControlLabel control={
							<Switch checked={recurring_deal_is_active}
								disabled={recurring_deal_toggle_disabled}
						        onChange={recurring_deal_toggle_clicked}> </Switch> 
						} label={recurring_deal_toggle_text} />
					

					
				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
				<li><a onClick={navigate_to_UpdateDeal}> {change_one_time_deal_button_text} </a></li>
				<li><a onClick={navigate_to_UpdateRecurringDeal}> {change_recurring_deal_button_text} </a></li>
				<li><a onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
				<li><a  className = "active">Activate or Deactivate Deals</a></li>
				<li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
				<li><a onClick={navigate_to_ViewProfile}>View My Profile</a></li>
				<li><a onClick={navigate_to_Billing}>Billing</a></li>
				<li><a onClick={navigate_to_FAQs}>FAQs</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default ActivateDeactivateDeal;
