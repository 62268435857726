import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc, and } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';


export function FAQs(props) {

	const navigate = useNavigate()

	const navigate_to_Dashboard = () => {
		navigate("/");
	}

	const navigate_to_UpdateImages = () => {
		navigate("/update_images");
	}

	const navigate_to_UpdateDeal = () => {
		navigate("/update_deal")
	}

	const navigate_to_UpdateRecurringDeal = () => {
	    navigate("/update_recurring_deal")
	}

	const navigate_to_ActivateDeactivateDeal = () => {
		navigate("/activate_deactivate_deal")
	}

	const navigate_to_ViewRedemptions = () => {
		navigate("/view_redemptions");
	}

	const navigate_to_ViewProfile = () => {
		navigate("/view_profile");
	}

	const navigate_to_Billing = () => {
		navigate("/billing");
	}
	
    const currentUser = auth.currentUser.uid
 
	const [change_one_time_deal_button_text, set_change_one_time_deal_button_text] = useState("Loading... ")
	const [change_recurring_deal_button_text, set_change_recurring_deal_button_text] = useState("Loading... ")
	const [change_images_button_text, set_change_images_button_text] = useState("Loading... ")

	const [unique_business_code, set_unique_business_code] = useState("Loading... ")


	useEffect(() => {
	  if (currentUser === process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
	    navigate("/dealio_admin");
	  }

	  refresh_set_one_time_deal_recurring_deal_images_text()
	  refresh_unique_business_code()
	}, [])

	const refresh_set_one_time_deal_recurring_deal_images_text = async () => {
		const docRef = doc(db, "businesses", currentUser)
		const docSnap = await getDoc(docRef)

		if (docSnap.exists()) {
			if (docSnap.data()["deal_desc"] === undefined || docSnap.data()["deal_desc"] === "" ) {
			  set_change_one_time_deal_button_text("Create One-Time Deal")
			} else {
			  set_change_one_time_deal_button_text("Update One-Time Deal")
			}

			if (docSnap.data()["recurring_deal_desc"] === undefined || docSnap.data()["recurring_deal_desc"] === "" ) {
			  set_change_recurring_deal_button_text("Create Deal for Returning Customers")
			} else {
			  set_change_recurring_deal_button_text("Update Deal for Returning Customers")
			}

			if (docSnap.data()["images"] === undefined || docSnap.data()["images"].length === 0 ) {
			  set_change_images_button_text("Upload Images")
			} else {
			  set_change_images_button_text("Update Images")
			}
		}
	}

	const refresh_unique_business_code = async () => {
		const docRef = doc(db, "businesses", currentUser)
	  	const docSnap = await getDoc(docRef)

	  	if (docSnap.exists()) {
	  		const c = docSnap.data()["four_digit_code"];
			set_unique_business_code(c);
		}
	}


	


	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200">
			<div>
				<div style={{margin: "30px"}} >

					<h1 className="text-4xl font-bold mb-3"> Frequently Asked Questions </h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > How does a customer redeem the deal at my location? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						The customer will show your cashier a screen on the Dealio website that says "Enter the code presented by the cashier".
						Your business's unique 4-digit deal redemption code is: 
						<br></br><b style={{marginLeft: "200px"}} >{unique_business_code}</b> 
						<br></br>Please have your cashier provide the code to the customer. They will enter it into their screen, and then show you a confirmation page
						which says "Congratulations! Your deal is now confirmed."
						Once your cashier has viewed the confirmation page, then they can apply the deal and complete the checkout.
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > What is the difference between my Deal and my Deal for Returning Customers? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						Your Deal is a one-time promotion that attracts customers and helps them discover your business. Once they've redeemed your Deal, it
						is no longer available to them; rather, the Deal for Returning Customers will become available for them for every subsequent visit to the business
						after the first visit. A typical Deal for Returning Customers is a small discount; 
						for example: "Receive 10% off every subsequent purchase at this location".
					</h1>
				
					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > How much does Dealio’s service cost? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						Dealio has a unique business model: we only charge $0.50 per deal redeemed. If a ticket amounts to 
						$10, then we charge $0.50. If the ticket amounts to $100, then we charge $0.50. 
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > How do I pay my invoices? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						Visit the Billing tab on the sidebar, which will display details about outstanding invoices. Each invoice will be emailed 
						to your email address on file (this is usually the email address you use to log in).
						The email subject will contain the phrase "Dealio Invoice" and the date of the bill. 
						There, you can find a Stripe payment link: click on it, and you will be prompted to enter payment details and complete the payment.
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > How can I monitor how many people are seeing and using my deals? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						Visit the Redemptions & Views page on the left-hand sidebar. There, you can view how many people have redeemed deals at your location,
						as well as how many people have viewed your business page. 
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > What does it mean to deactivate a deal? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						If you decide you no longer want customers to use either the One-Time Deal or Deal for Returning Customers, simply navigate to the 
						"Activate or Deactivate Deals" page, and toggle the relevant deal from Active --> Inactive. Then, customers will no longer be able to 
						see or use that particular deal.
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > How do I change my Profile info? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						Email us at contact@joindealio.com to change details on your Profile. This is useful if, for example, your store changes locations.
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > Where can I view the Terms of Service? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						You can view the Terms of Service <a href="/#/terms_of_service_for_businesses" target="_blank" style={{color: "blue"}}> here. </a>
					</h1>

					<b><h1 style={{fontSize:"22px", marginTop: "40px"}} > Who do I contact with additional questions? </h1></b>
					<h1 style={{fontSize:"22px", marginTop: "5px"}} > 
						You can email Aaron at aaron@joindealio.com, or give him a call at (805) 881-3377.
					</h1>


				</div>



			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_Dashboard}>Dashboard</a></li>
				<li><a onClick={navigate_to_UpdateDeal}>{change_one_time_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateRecurringDeal}>{change_recurring_deal_button_text}</a></li>
				<li><a onClick={navigate_to_UpdateImages}>{change_images_button_text}</a></li>
				<li><a onClick={navigate_to_ActivateDeactivateDeal}>Activate or Deactivate Deals</a></li>
				<li><a onClick={navigate_to_ViewRedemptions}> Redemptions & Views </a></li>
				<li><a onClick={navigate_to_ViewProfile}>View Profile</a></li>
				<li><a onClick={navigate_to_Billing}>Billing</a></li>
				<li><a className = "active"> FAQs </a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default FAQs;