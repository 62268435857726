import { getAuth, signOut } from "firebase/auth";
import { storage, db, auth } from "../firebase";


export function Logout() {
    signOut(auth).then(() => {
        // Sign-out successful.
        return (
            <h1>
                You have successfully signed out!
            </h1>
        )
    }).catch((error) => {
        // An error happened.
        return (
            <h1>
                There was an error while signing out...
            </h1>
        )
    });
}



export default Logout;
