import logo from './logo.svg';
import './App.css';
import { AuthProvider } from "./Auth.js";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from "./components/Homepage.js"
import Login from "./components/Login.js"
import PasswordReset from "./components/PasswordReset.js"
import Signup from "./components/Signup.js"
import RequireAuth from "./RequireAuth.js"
import UpdateDeal from "./components/UpdateDeal.js"
import UpdateImages from "./components/UpdateImages.js"
import Navbar from "./components/Navbar.js"
import Logout from "./components/Logout.js"
import ViewProfile from "./components/ViewProfile.js"
import NewBusinessApproval from "./components/DealioAdmin.js"
import SignupInfo from "./components/SignupInfo.js"
import UpdateRecurringDeal from "./components/UpdateRecurringDeal.js"
import ActivateDeactivateDeal from "./components/ActivateDeactivateDeal.js"
import ViewRedemptions from "./components/ViewRedemptions.js"
import Billing from "./components/Billing.js"
import AdminBilling from "./components/AdminBilling.js"
import FAQs from "./components/FAQ.js"
import TermsOfServiceForBusinesses from "./components/TermsOfServiceForBusinesses.js"
import AdminViewAllBusinesses from "./components/AdminViewAllBusinesses.js"
import AdminUpdateBusinessInfo from "./components/AdminUpdateBusinessInfo.js"
import AdminViewMetrics from "./components/AdminViewMetrics.js"



function App() {
  return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<Login/>} />
                        <Route path="/password_reset" element={<PasswordReset/>} />
                        <Route path="/signup" element={<Signup/>} />                       

                        <Route
                            path="/"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <Homepage />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/info"
                            element={
                                <RequireAuth>
                                    <SignupInfo />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/logout"
                            element={
                                <RequireAuth>
                                    <Logout />
                                </RequireAuth>
                            }
                        />                 

                        <Route
                            path="/update_deal"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <UpdateDeal />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/update_recurring_deal"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <UpdateRecurringDeal />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/update_images"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <UpdateImages />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/activate_deactivate_deal"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <ActivateDeactivateDeal />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/view_redemptions"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <ViewRedemptions />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/view_profile"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <ViewProfile />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/billing"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <Billing />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/dealio_admin"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <NewBusinessApproval />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/admin_billing"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <AdminBilling />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/frequently_asked_questions"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <FAQs />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/admin_view_all_businesses"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <AdminViewAllBusinesses />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/admin_update_business_info"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <AdminUpdateBusinessInfo />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="/admin_view_metrics"
                            element={
                                <RequireAuth>
                                    <Navbar />
                                    <AdminViewMetrics />
                                </RequireAuth>
                            }
                        />



                        <Route path="/terms_of_service_for_businesses" element={<TermsOfServiceForBusinesses/>} />   

                    


              

                    </Routes>


                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
