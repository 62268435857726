
import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc, and, or } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert, FormControlLabel, Radio, Switch } from '@mui/material';
import RadioGroup  from '@mui/material/RadioGroup'





export function AdminUpdateBusinessInfo(props) {

	const navigate = useNavigate()
    const currentUser = auth.currentUser.uid
    const [success_message, set_success_message] = useState([]);
    const [business_email_address, set_business_email_address] = useState("");
    const [no_businesses_found_message, set_no_businesses_found_message] = useState("");
    const [hide_business_update_info_section, set_hide_business_update_info_section] = useState(true);
    const [business_document_ID, set_business_document_ID] = useState(-1);

    // All business info that can be updated 
    const [business_name, set_business_name] = useState("");
    const [business_phone_number, set_business_phone_number] = useState("");
    const [business_street_address, set_business_street_address] = useState("");
    const [business_city, set_business_city] = useState("");
    const [business_state, set_business_state] = useState("");
    const [business_zip_code, set_business_zip_code] = useState("");

    const [four_digit_code, set_four_digit_code] = useState("");
    const [user_first_name, set_user_first_name] = useState("");
    const [user_last_name, set_user_last_name] = useState("");
    const [business_approved, set_business_approved] = useState("");
    const [business_category, set_business_category] = useState("")

    // Update Images state
    const [update_images_current_images, set_update_images_current_images] = useState([]);
    const [update_images_images_display_array, set_update_images_images_display_array] = useState([]); 
	const [update_images_images_files, set_update_images_images_files] = useState([]);

	// One-Time-Deal state
	const [one_time_deal_deal_desc, set_one_time_deal_deal_desc] = useState(""); 
	const [one_time_deal_deal_restrictions_conditions, set_one_time_deal_deal_restrictions_conditions] = useState("")
	const [one_time_deal_image_file, set_one_time_deal_image_file] = useState([]);
	const [one_time_deal_image_display, set_one_time_deal_image_display] = useState([]);
	const [one_time_deal_current_deal, set_one_time_deal_current_deal] = useState("Loading... ");

	const [one_time_deal_is_active, set_one_time_deal_is_active] = useState(false);
	const [one_time_deal_toggle_disabled, set_one_time_deal_toggle_disabled] = useState(false);
	const [one_time_deal_toggle_text, set_one_time_deal_toggle_text] = useState("");



	// Recurring Deal state
	const [recurring_deal_recurring_deal_desc, set_recurring_deal_recurring_deal_desc] = useState(""); 
	const [recurring_deal_recurring_deal_restrictions_conditions, set_recurring_deal_recurring_deal_restrictions_conditions] = useState("")
	const [recurring_deal_image_file, set_recurring_deal_image_file] = useState([]);
	const [recurring_deal_image_display, set_recurring_deal_image_display] = useState([]);
	const [recurring_deal_current_deal, set_recurring_deal_current_deal] = useState("Loading... ");

	const [recurring_deal_is_active, set_recurring_deal_is_active] = useState(false);
	const [recurring_deal_toggle_disabled, set_recurring_deal_toggle_disabled] = useState(false);
	const [recurring_deal_toggle_text, set_recurring_deal_toggle_text] = useState("");



    const navigate_to_NewBusinessApproval = () => {
    	navigate("/dealio_admin")
    }
  	const navigate_to_AdminBilling = () => {
    	navigate("/admin_billing")
    }
    const navigate_to_ViewAllBusinesses = () => {
    	navigate("/admin_view_all_businesses")
    }
    const navigate_to_AdminViewMetrics = () => {
    	navigate("/admin_view_metrics")
    }

    const submit_updated_info = async() => {
    	const docRef = doc(db, 'businesses', business_document_ID);

    	// General Business Info
    	await setDoc(docRef, {
            	business_name: business_name,
            	business_phone_number: business_phone_number,
            	business_address_street_address: business_street_address,
            	business_address_city: business_city,
            	business_address_state: business_state,
            	business_address_zip_code: business_zip_code,
            	four_digit_code: four_digit_code,
            	user_first_name: user_first_name,
            	user_last_name: user_last_name,
            	approved: business_approved,
            	business_category: business_category,
            	one_time_deal_is_active: one_time_deal_is_active,
            	recurring_deal_is_active: recurring_deal_is_active,
	        }, { merge: true })


    	// Update Images
		const business_images_URLS = []

		for (var i = 0; i < update_images_images_files.length; i++) {
			const image = update_images_images_files[i]
			await update_images_uploadImage(image, business_images_URLS)
		}
        await setDoc(docRef, {
            	images: business_images_URLS
	        }, { merge: true })
		set_update_images_images_files([])


		// One Time Deal
		var one_time_deal_deal_image_URL = []

		for (var i = 0; i < one_time_deal_image_file.length; i++) {
			const image = one_time_deal_image_file[i]
			if (typeof image === 'string') {
				one_time_deal_deal_image_URL = [image]
			} else {
				await one_time_deal_uploadImage(image, one_time_deal_deal_image_URL)
			}
		}

		var one_time_deal_image_URL;
		if (one_time_deal_deal_image_URL.length > 0) {
			one_time_deal_image_URL = one_time_deal_deal_image_URL[0]
			await setDoc(docRef, { 
	        	deal_desc: one_time_deal_deal_desc,
	        	deal_restrictions_conditions: one_time_deal_deal_restrictions_conditions,
	        	deal_image: one_time_deal_image_URL
	        }, { merge: true })
		}

        await setDoc(docRef, { 
	        	deal_desc: one_time_deal_deal_desc,
	        	deal_restrictions_conditions: one_time_deal_deal_restrictions_conditions,
	        }, { merge: true })
	        	
		set_one_time_deal_deal_desc("")
		set_one_time_deal_deal_restrictions_conditions("")
		set_one_time_deal_image_file([])


		// Recurring Deal
		const recurring_deal_deal_image_URL = []

		for (var i = 0; i < recurring_deal_image_file.length; i++) {
			const image = recurring_deal_image_file[i]
			await recurring_deal_uploadImage(image, recurring_deal_deal_image_URL)
		}

		var recurring_deal_image_URL;
		if (recurring_deal_deal_image_URL.length > 0) {
			recurring_deal_image_URL = recurring_deal_deal_image_URL[0];
			await setDoc(docRef, { 
	        	recurring_deal_desc: recurring_deal_recurring_deal_desc,
	        	recurring_deal_restrictions_conditions: recurring_deal_recurring_deal_restrictions_conditions,
	        	recurring_deal_image: recurring_deal_image_URL
	        }, { merge: true })
		}
        await setDoc(docRef, { 
	        	recurring_deal_desc: recurring_deal_recurring_deal_desc,
	        	recurring_deal_restrictions_conditions: recurring_deal_recurring_deal_restrictions_conditions,
	        }, { merge: true })

 	
		set_recurring_deal_recurring_deal_desc("")
		set_recurring_deal_recurring_deal_restrictions_conditions("")
		set_recurring_deal_image_file([])

		await set_success_message([
				<Alert
                    severity="success"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_success_message([])}}>
                        	CLOSE
                        </Button>
                    }
                    >
                    Updates have been published. 
                </Alert>
			]);
		// refresh updated info
		search_and_populate_business_by_email()

    }

    // Update Images functions
    const update_images_new_image_uploaded = (event) => {
		const files = event.target.files;
		const index = update_images_images_files.length

		for (let i = 0; i < files.length; i++) {
			set_update_images_images_files(previous_images_files => [...previous_images_files, files[i]])
		}
	}
	async function update_images_uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            lst.push(file)
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            lst.push(downloadURL)
        })
    }
    const update_images_delete_image = (index) => {
		update_images_images_files.splice(index, 1)
		set_update_images_images_files([...update_images_images_files])
	}
	useEffect(() => {
		set_update_images_images_display_array([])
		for (let i = 0; i < update_images_images_files.length; i++) {
			set_update_images_images_display_array(previous_images_array => [...previous_images_array, 
									                   <div className="image">
									                       <img src={URL.createObjectURL(update_images_images_files[i])}
									                       		alt="image"/>
									                       	<span onClick={() => {update_images_delete_image(i)}}> X </span>
									                   </div> ])
		}
	}, [update_images_images_files])


	// One Time Deal functions
	const one_time_deal_change_deal_desc = (event) => {
		set_one_time_deal_deal_desc(event.target.value);
	}
	const one_time_deal_change_deal_restrictions_conditions = (event) => {
		set_one_time_deal_deal_restrictions_conditions(event.target.value)
	}
	async function one_time_deal_uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            lst.push(file)
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            lst.push(downloadURL)
        })
    }
    const one_time_deal_new_image_uploaded = (event) => {
		set_one_time_deal_image_file([event.target.files[0]]);		
	}
	useEffect(() => {
		set_one_time_deal_image_display([]);
		if (one_time_deal_image_file.length <= 0) {return}

		if (typeof one_time_deal_image_file[0] === 'string') {
			set_one_time_deal_image_display([ <div className="image">
				                       <img src={one_time_deal_image_file[0]}
				                       		alt="image"/>
				                       	<span onClick={() => {set_one_time_deal_image_file([])}}> X </span>
				                   </div> ])
		} else {
			set_one_time_deal_image_display([ <div className="image">
				                       <img src={URL.createObjectURL(one_time_deal_image_file[0])}
				                       		alt="image"/>
				                       	<span onClick={() => {set_one_time_deal_image_file([])}}> X </span>
				                   </div> ])
		}
		
	}, [one_time_deal_image_file])

	const one_time_deal_toggle_clicked = async () => {
		const new_one_time_deal_is_active = !one_time_deal_is_active;
		console.log("now one time deal active: ", new_one_time_deal_is_active);
		await set_one_time_deal_is_active(new_one_time_deal_is_active);
		if (new_one_time_deal_is_active === true) {
			await set_one_time_deal_toggle_text("Currently Active");
		} else {
			await set_one_time_deal_toggle_text("Currently Inactive");
		}
	}


	// Recurring Deal Functions
	const recurring_deal_change_deal_desc = (event) => {
		set_recurring_deal_recurring_deal_desc(event.target.value);
	}
	const recurring_deal_change_deal_restrictions_conditions = (event) => {
		set_recurring_deal_recurring_deal_restrictions_conditions(event.target.value)
	}
	async function recurring_deal_uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            lst.push(file)
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            lst.push(downloadURL)
        })
    }
    const recurring_deal_new_image_uploaded = (event) => {
		set_recurring_deal_image_file([event.target.files[0]]);		
	}
	useEffect(() => {
		set_recurring_deal_image_display([]);
		if (recurring_deal_image_file.length <= 0) {return}
		if (typeof recurring_deal_image_file[0] === 'string') {
			set_recurring_deal_image_display([ <div className="image">
				                       <img src={recurring_deal_image_file[0]}
				                       		alt="image"/>
				                       	<span onClick={() => {set_recurring_deal_image_file([])}}> X </span>
				                   </div> ])
		} else {
			set_recurring_deal_image_display([ <div className="image">
				                       <img src={URL.createObjectURL(recurring_deal_image_file[0])}
				                       		alt="image"/>
				                       	<span onClick={() => {set_recurring_deal_image_file([])}}> X </span>
				                   </div> ])
		}
		
	}, [recurring_deal_image_file])

	const recurring_deal_toggle_clicked = async () => {
		const new_recurring_deal_is_active = !recurring_deal_is_active;
		console.log("now recurring deal active: ", new_recurring_deal_is_active);
		await set_recurring_deal_is_active(new_recurring_deal_is_active);
		if (new_recurring_deal_is_active === true) {
			await set_recurring_deal_toggle_text("Currently Active");
		} else {
			await set_recurring_deal_toggle_text("Currently Inactive");
		}
	}



	const reset_all_business_info_state_variables = () => {
		set_business_document_ID(-1);

	    // All business info that can be updated 
	    set_business_name("");
	    set_business_phone_number("");
	    set_business_street_address("");
	    set_business_city("");
	    set_business_state("");
	    set_business_zip_code("");

	    set_four_digit_code("");
	    set_user_first_name("");
	    set_user_last_name("");
	    set_business_approved("")
	    set_business_category("");

	    // Update Images state
	    set_update_images_current_images([]);
	    set_update_images_images_display_array([]);
		set_update_images_images_files([]);

		// One-Time-Deal state
		set_one_time_deal_deal_desc("");
		set_one_time_deal_deal_restrictions_conditions("");
		set_one_time_deal_image_file([]);
		set_one_time_deal_image_display([]);
		set_one_time_deal_current_deal("Loading... ");

		// Recurring Deal state
		set_recurring_deal_recurring_deal_desc("");
		set_recurring_deal_recurring_deal_restrictions_conditions("");
		set_recurring_deal_image_file([]);
		set_recurring_deal_image_display([]);
		set_recurring_deal_current_deal("Loading... ");
	}

    const search_and_populate_business_by_email = async () => {
    	reset_all_business_info_state_variables()
    	const q = query(collection(db, "businesses"), where("user_email", "==", business_email_address));
    	const querySnapshot = await getDocs(q);
    	if (querySnapshot.size == 0) {
    		set_no_businesses_found_message("No businesses with that email address were found. ");
    		return;
    	} 

    	set_hide_business_update_info_section(false);

    	querySnapshot.forEach(async (doc) => {
    		console.log("FOUND BUSINESS DOCUMENT: ", doc.data())
    		set_business_document_ID(doc.id);


    		// business account info
    		set_business_name(doc.data()["business_name"]);
    		set_business_phone_number(doc.data()["business_phone_number"]);
    		set_business_street_address(doc.data()["business_address_street_address"]);
    		set_business_city(doc.data()["business_address_city"]);
    		set_business_state(doc.data()["business_address_state"]);
    		set_business_zip_code(doc.data()["business_address_zip_code"]);
    		set_four_digit_code(doc.data()["four_digit_code"]);
    		set_user_first_name(doc.data()["user_first_name"]);
    		set_user_last_name(doc.data()["user_last_name"]);
    		set_business_approved(doc.data()["approved"]);
    		set_business_category(doc.data()["business_category"]);


    		// UPDATE IMAGES 
    		if (doc.data()["images"] !== undefined && doc.data()["images"].length > 0) {
				const images_list_download_URLs = doc.data()["images"]; 
				const images_content = [];

				images_list_download_URLs.forEach((im) => {
					images_content.push(
						<img src={im} 
							 alt="image"
							 width="300px"
							 style={{borderRadius: "15px", display:"inline" , margin: "10px"}} />
					)
				})

				set_update_images_current_images(images_content)
			} else {
				set_update_images_current_images(
					<h3> You do not have any images for your business- please use the Update Images section below to add some! </h3>
				)
			}



			// ONE TIME DEAL
			if (doc.exists()) {
				// toggle initialize
				if (doc.data()["deal_desc"] !== undefined 
						&& doc.data()["deal_restrictions_conditions"] !== undefined
					    && doc.data()["deal_image"] !== undefined
					    && doc.data()["one_time_deal_is_active"] !== undefined ) {
					const one_time_deal_is_active = doc.data()["one_time_deal_is_active"]
					await set_one_time_deal_is_active(one_time_deal_is_active)
					await set_one_time_deal_toggle_disabled(false)
					if (one_time_deal_is_active === true) {
						await set_one_time_deal_toggle_text("Currently Active")
					} else if (one_time_deal_is_active === false) {
						await set_one_time_deal_toggle_text("Currently Inactive")
					}
				} else {
					set_one_time_deal_toggle_disabled(true)
					set_one_time_deal_is_active(false)
					set_one_time_deal_toggle_text("N/A")
				}
				if (doc.data()["deal_desc"] !== undefined 
						&& doc.data()["deal_restrictions_conditions"] !== undefined
					    && doc.data()["deal_image"] !== undefined
					    && doc.data()["one_time_deal_is_active"] !== undefined ) {
					const deal_description = doc.data()["deal_desc"];
					const deal_restrictions_conditions = doc.data()["deal_restrictions_conditions"]
					const deal_image_download_URL = doc.data()["deal_image"];
					const one_time_deal_is_active = doc.data()["one_time_deal_is_active"]

					set_one_time_deal_deal_desc(deal_description);
					set_one_time_deal_deal_restrictions_conditions(deal_restrictions_conditions);
					set_one_time_deal_image_file([deal_image_download_URL]);
			
					set_one_time_deal_current_deal(
						<div> 
							<b><h2 style={{marginTop:"15px"}}> Title: </h2></b> 
								<h2> {deal_description} </h2>
							<b><h2 style={{marginTop:"15px"}}> Restrictions & Conditions: </h2></b> 
								<h2> {deal_restrictions_conditions} </h2>
							<b><h2 style={{marginTop:"15px"}}> Image: </h2></b> 
								<img src={deal_image_download_URL} 
									 alt="image"
									 width="300px" 
									 style={{borderRadius: "15px", marginBottom: "100px"}} />
						</div>
					)
				} else if (doc.data()["deal_desc"] !== undefined) {
					const deal_description = doc.data()["deal_desc"];
					set_one_time_deal_deal_desc(deal_description);

					set_one_time_deal_current_deal(
						<div> 
							<b><h2> Title: </h2></b> 
								<h2> {deal_description} </h2>
						</div>
					)
				} else {
					set_one_time_deal_current_deal(
						<h3> You do not have a currently active deal- please use the Update Deal Description & Image section below to create one! </h3>
					)
				}
			} 






			// RECURRING DEAL
			if (doc.exists()) {
				// toggle initialize
				if (doc.data()["recurring_deal_desc"] !== undefined 
						&& doc.data()["recurring_deal_restrictions_conditions"] !== undefined
					    && doc.data()["recurring_deal_image"] !== undefined
					    && doc.data()["recurring_deal_is_active"] !== undefined ) {
					const recurring_deal_is_active = doc.data()["recurring_deal_is_active"];
					set_recurring_deal_is_active(recurring_deal_is_active);
					set_recurring_deal_toggle_disabled(false);
					if (recurring_deal_is_active === true) {
						set_recurring_deal_toggle_text("Currently Active")
					} else if (recurring_deal_is_active === false) {
						set_recurring_deal_toggle_text("Currently Inactive")
					}			
				} else {
					set_recurring_deal_toggle_disabled(true);
					set_recurring_deal_is_active(false);
					set_recurring_deal_toggle_text("N/A");
				}
				if (doc.data()["recurring_deal_desc"] !== undefined 
						&& doc.data()["recurring_deal_restrictions_conditions"] !== undefined
					    && doc.data()["recurring_deal_image"] !== undefined) {
					const recurring_deal_description = doc.data()["recurring_deal_desc"];
					const recurring_deal_restrictions_conditions = doc.data()["recurring_deal_restrictions_conditions"]
					const recurring_deal_image_download_URL = doc.data()["recurring_deal_image"];

					set_recurring_deal_recurring_deal_desc(recurring_deal_description);
					set_recurring_deal_recurring_deal_restrictions_conditions(recurring_deal_restrictions_conditions);
					set_recurring_deal_image_file([recurring_deal_image_download_URL]);

					set_recurring_deal_current_deal(
						<div> 
							<b><h2 style={{marginTop:"15px"}}> Title: </h2></b> 
								<h2> {recurring_deal_description} </h2>
							<b><h2 style={{marginTop:"15px"}}> Restrictions & Conditions: </h2></b> 
								<h2> {recurring_deal_restrictions_conditions} </h2>
							<b><h2 style={{marginTop:"15px"}}> Image: </h2></b> 
								<img src={recurring_deal_image_download_URL} 
									 alt="image"
									 width="300px" 
									 style={{borderRadius: "15px", marginBottom: "100px"}} />
						</div>
					)
				} else if (doc.data()["recurring_deal_desc"] !== undefined) {
					const recurring_deal_description = doc.data()["recurring_deal_desc"];
					set_recurring_deal_current_deal(
						<div> 
							<b><h2> Title: </h2></b> 
								<h2> {recurring_deal_description} </h2>
						</div>
					)
				} else {
					set_recurring_deal_current_deal(
						<h3> You do not have a currently active deal- please use the Update Deal Description & Image section below to create one! </h3>
					)
				}
			}









    	})
    }

	
	

	const text_field_styling = {
        
        width: "500px",
    }

   



	if (currentUser !== process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
		return (
			<div>
				<h1> You do not have access to this page. </h1>
			</div>
		)
	}


	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200">
			<div>
				<div>

					<h1 className="text-4xl font-bold mb-3"
					    style={{marginTop: "10px"}}> Admin- Update Business Info </h1>

					<TextField label="Email Address of Business Account"
                           value={business_email_address}
                           onChange={(event) => {set_business_email_address(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                    <Button variant="contained"
	                        type="submit"
	                        style={{fontSize: "16px", display: "block", margin: "auto", marginTop: "30px", marginBottom: "50px"}}
	                        onClick={() => {search_and_populate_business_by_email(); }}>
		                Retrieve Business's Info
	                </Button>

	                {no_businesses_found_message}
	                {success_message}


	                <div style={{marginBottom: "200px", display: hide_business_update_info_section ? "none" : ""}}>
	    										
						<h1 className="text-4xl font-bold mb-3"
							style={{marginTop: "20px"}}> Update One-Time Deal Description & Image </h1>
						<h1> *We recommend a deal of 20% off or better for the one-time deal. </h1>

						<h1 style={{marginTop: "50px"}}> Set the Description </h1>
						<input type="text" 
							   value={one_time_deal_deal_desc}
						       className="input max-w-xs" 
						       onChange={one_time_deal_change_deal_desc} 
						       placeholder="Enter Deal Description" />

						<h1 style={{marginTop: "50px"}}> Set the Restrictions & Conditions </h1>
						<input type="text" 
						       value={one_time_deal_deal_restrictions_conditions}
						       className="input max-w-xs" 
						       onChange={one_time_deal_change_deal_restrictions_conditions} 
						       placeholder="Restrictions & Conditions" />

						<h1 style={{marginTop: "50px"}}> Choose an Image </h1>
						<input type="file" 
							   className="file-input w-full max-w-xs" 
							   accept="image\/jpeg, image\/jpg, image\/png" 
							   onChange={one_time_deal_new_image_uploaded} 
							   style={{display: "block"}} />
						<output style={{marginBottom: "0px"}}> {one_time_deal_image_display} </output>

						<b><h1 style={{marginTop: "20px", fontSize: "23px"}}> Status: </h1></b>
						<FormControlLabel control={
								<Switch checked={one_time_deal_is_active}
									disabled={one_time_deal_toggle_disabled}
							        onChange={one_time_deal_toggle_clicked}> </Switch> 
							} label={one_time_deal_toggle_text} />




						<h1 className="text-4xl font-bold mb-3"
							style={{marginTop: "20px"}}> Update Recurring Deal Description & Image </h1>
						<h1> *We recommend a deal of 10% off or better for returning customers. </h1>

						<h1 style={{marginTop: "50px"}}> Set the Description </h1>
						<input type="text" 
						       value={recurring_deal_recurring_deal_desc}
						       className="input max-w-xs" 
						       onChange={recurring_deal_change_deal_desc} 
						       placeholder="Enter Deal Description" />

						<h1 style={{marginTop: "50px"}}> Set the Restrictions & Conditions </h1>
						<input type="text" 
							   value={recurring_deal_recurring_deal_restrictions_conditions}
						       className="input max-w-xs" 
						       onChange={recurring_deal_change_deal_restrictions_conditions} 
						       placeholder="Restrictions & Conditions" />

						<h1 style={{marginTop: "50px"}}> Choose an Image </h1>
						<input type="file" 
							   className="file-input w-full max-w-xs" 
							   accept="image\/jpeg, image\/jpg, image\/png" 
							   onChange={recurring_deal_new_image_uploaded} 
							   style={{display: "block"}} />
						<output style={{marginBottom: "0px"}}> {recurring_deal_image_display} </output>

						<b><h1 style={{marginTop: "20px", fontSize: "23px"}}> Status: </h1></b>
						<FormControlLabel control={
								<Switch checked={recurring_deal_is_active}
									disabled={recurring_deal_toggle_disabled}
							        onChange={recurring_deal_toggle_clicked}> </Switch> 
							} label={recurring_deal_toggle_text} />




                        <h1 className="text-4xl font-bold mb-3"
						    style={{marginTop: "20px"}}>Update Images</h1>

					    <h2 className="text-4xl font-bold mb-3"> Current Images </h2>
						{ update_images_current_images }

						<h1 style={{marginTop: "50px"}}> Choose between 1-10 images.  </h1>
						<input type="file" multiple="multiple" className="file-input w-full max-w-xs" accept="image\/jpeg, image\/jpg, image\/png" 
							   onChange={update_images_new_image_uploaded} />
						<output> {update_images_images_display_array} </output>




						<h1 className="text-4xl font-bold mb-3"
							style={{marginTop: "20px"}}> Update General Business Info </h1>
                    
                    	<TextField label="Business Name"
		                           value={business_name}
		                           onChange={(event) => {set_business_name(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",   }} />

		                <TextField label="Business Phone Number"
		                           value={business_phone_number}
		                           onChange={(event) => {set_business_phone_number(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",   }} />

		                <TextField label="Business Street Address"
		                           value={business_street_address}
		                           onChange={(event) => {set_business_street_address(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",   }} />

		                <TextField label="Business City"
		                           value={business_city}
		                           onChange={(event) => {console.log("ASFDFSF: ", event.target.value); set_business_city(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",  }} />

		                <TextField label="Business State"
		                           value={business_state}
		                           onChange={(event) => {set_business_state(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",   }} />

		                <TextField label="Business Zip Code"
		                           value={business_zip_code}
		                           onChange={(event) => {set_business_zip_code(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",    }} />


		                <TextField label="Four Digit Code"
		                           value={four_digit_code}
		                           onChange={(event) => {set_four_digit_code(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",   }} />

		                <TextField label="User First Name"
		                           value={user_first_name}
		                           onChange={(event) => {set_user_first_name(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",   }} />

		                <TextField label="User Last Name"
		                           value={user_last_name}
		                           onChange={(event) => {set_user_last_name(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "25px",    }} />

		                <h2 style={{marginTop: "25px"}}> Note: Approval status has 3 possible values: "new sign up", "true", or "false" </h2>
		                <TextField label="Business Approval Status"
		                           value={business_approved}
		                           onChange={(event) => {set_business_approved(event.target.value)}}
		                           variant="outlined"
		                           type="text"
		                           style={{width: "500px", display: "block", marginTop: "5px",    }} />




		                <RadioGroup>
		                        <FormControlLabel value="food" control={<Radio />} label="Food" 
		                        	              checked={business_category === "food"}
		                                          onClick={() => set_business_category("food")} />
		                        <FormControlLabel value="health_beauty" control={<Radio />} label="Health & Beauty" 
							                      checked={business_category === "health_beauty"}
		                                          onClick={() => set_business_category("health_beauty")} />
		                        <FormControlLabel value="grocery" control={<Radio />} label="Grocery" 
		                                          checked={business_category === "grocery"}
		                                          onClick={() => set_business_category("grocery")} />
		                        <FormControlLabel value="clothing" control={<Radio />} label="Clothing" 
		                                          checked={business_category === "clothing"}
		                                          onClick={() => set_business_category("clothing")} />
		                        <FormControlLabel value="fitness" control={<Radio />} label="Fitness" 
		                                          checked={business_category === "fitness"}
		                                          onClick={() => set_business_category("fitness")} />
		                        <FormControlLabel value="home_garden" control={<Radio />} label="Home & Garden" 
		                                          checked={business_category === "home_garden"}
		                                          onClick={() => set_business_category("home_garden")} />
		                        <FormControlLabel value="automotive" control={<Radio />} label="Automotive" 
		                                          checked={business_category === "automotive"}
		                                          onClick={() => set_business_category("automotive")} />
		                        <FormControlLabel value="gifts_florists" control={<Radio />} label="Gifts & Florists" 
		                                          checked={business_category === "gifts_florists"}
		                                          onClick={() => set_business_category("gifts_florists")} />
		                        <FormControlLabel value="home_maintenance" control={<Radio />} label="Home Maintenance" 
		                                          checked={business_category === "home_maintenance"}
		                                          onClick={() => set_business_category("home_maintenance")} />   
		                        <FormControlLabel value="entertainment" control={<Radio />} label="Entertainment" 
		                                          checked={business_category === "entertainment"}
		                                          onClick={() => set_business_category("entertainment")} />
		                        <FormControlLabel value="pets_animal_care" control={<Radio />} label="Pets & Animal Care" 
		                                          checked={business_category === "pets_animal_care"}
		                                          onClick={() => set_business_category("pets_animal_care")} />
		                        <FormControlLabel value="sporting_goods" control={<Radio />} label="Sporting Goods" 
		                                          checked={business_category === "sporting_goods"}
		                                          onClick={() => set_business_category("sporting_goods")} />   
		                        <FormControlLabel value="retail" control={<Radio />} label="Retail" 
		                                          checked={business_category === "retail"}
                  							      onClick={() => set_business_category("retail")} />                                                                                                        
		                </RadioGroup>






		                <Button variant="contained"
		                        type="submit"
		                        style={{fontSize: "16px", display: "block", margin: "auto", marginTop: "30px", marginBottom: "50px"}}
		                        onClick={() => {submit_updated_info()}}>
			                Publish Updated Info
		                </Button>


                    </div>





				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_NewBusinessApproval}>New Business Approval</a></li>
				<li><a onClick={navigate_to_AdminBilling}>Admin Billing</a></li>
				<li><a onClick={navigate_to_ViewAllBusinesses}>View All Businesses</a></li>
				<li><a className = "active"> Update Business Info </a></li>
				<li><a onClick={navigate_to_AdminViewMetrics}>View Metrics</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default AdminUpdateBusinessInfo;
