import React, { useContext, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {  Navigate } from "react-router";
import { AuthContext } from "../Auth.js";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import { db, auth } from "../firebase";
import RadioGroup  from '@mui/material/RadioGroup'

import { Button, TextField, Alert, FormControlLabel, Radio } from '@mui/material';
import { useLocation,useNavigate,useParams } from "react-router-dom";

export function SignupInfo(props) {
  const navigate = useNavigate();



  return (
        <div>

            <div className="navbar bg-base-100">
            <div className="navbar-start">
                <a className="btn btn-ghost normal-case text-xl">Dealio</a>
            </div>
            <div className="navbar-center hidden lg:flex">
            </div>
          
            </div>
            <div class="hero flex flex-col">
            <b><h1 style={{fontSize: "25px", marginLeft: "300px", marginRight: "300px"}}> 
                You have successfully created a Dealio Business Account!
                <br></br>Within 24 hours, you will be notified via email whether your account is approved or denied. 
                <br></br>In the meantime, to access your Dashboard, read through the <a href="/#/terms_of_service_for_businesses" target="_blank" style={{color: "blue"}}> Terms of Service </a>, 
                            and click "I agree to the Terms of Service, take me to my dashboard"!

            </h1></b>



            <Button type="submit"
                    size="large"
                        style={{display: "block", margin: "auto", marginTop: "30px", marginBottom: "80px"}}
                        onClick={() => {navigate("/")}} >
                    I agree to the Terms of Service, take me to my dashboard
                </Button>

         
            </div>
        </div>

    );

}

export default SignupInfo;
