import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';



export function NewBusinessApproval(props) {

	const navigate = useNavigate()
    const currentUser = auth.currentUser.uid

    const navigate_to_NewBusinessApproval = () => {
    	navigate("/dealio_admin")
    }
  	const navigate_to_AdminBilling = () => {
    	navigate("/admin_billing")
    }
    const navigate_to_ViewAllBusinesses = () => {
    	navigate("/admin_view_all_businesses")
    }
    const navigate_to_UpdateBusinessInfo = () => {
    	navigate("/admin_update_business_info")
    }
    const navigate_to_AdminViewMetrics = () => {
    	navigate("/admin_view_metrics")
    }

    console.log("CURRENT USER: ", currentUser)

	const [deal_desc, set_deal_desc] = useState(""); 
	const [deal_restrictions_conditions, set_deal_restrictions_conditions] = useState("")
	const [image_file, set_image_file] = useState([]);
	const [image_display, set_image_display] = useState([]);
	const [updated_deal_status_message, set_updated_deal_status_message] = useState([]);
	const [update_deal_button_disabled, set_update_deal_button_disabled] = useState(false);
	const [current_deal, set_current_deal] = useState("Loading... ");

	const [unapproved_businesses, set_unapproved_businesses] = useState([]);
	const [approved_or_denied_business_message, set_approved_or_denied_business_message] = useState([]);


	const approve_business = async (document_ID, business_name) => {
		const docRef = doc(db, "businesses", document_ID)
        setDoc(docRef, { 
        	approved: "true",
        }, { merge: true })
        	.then(() => {
				set_approved_or_denied_business_message([
	                <Alert
	                    severity="info"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_approved_or_denied_business_message([])}}>
	                        	CLOSE
	                        </Button>
	                    }
	                    >
	                    You have approved the following business: {business_name}
	                </Alert>
	            ])
			})
			.catch((error) => {
				set_approved_or_denied_business_message([
	                <Alert
	                    severity="error"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_approved_or_denied_business_message([])}}>
	                        	CLOSE
	                        </Button>
	                    }
	                    >
	                    {error.code}
	                </Alert>
	            ])
			});
	}

	const deny_business = async (document_ID, business_name) => {
		const docRef = doc(db, "businesses", document_ID)
        setDoc(docRef, { 
        	approved: "false",
        }, { merge: true })
        	.then(() => {
				set_approved_or_denied_business_message([
	                <Alert
	                    severity="info"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_approved_or_denied_business_message([])}}>
	                        	CLOSE
	                        </Button>
	                    }
	                    >
	                    You have denied the following business: {business_name}
	                </Alert>
	            ])
			})
			.catch((error) => {
				set_approved_or_denied_business_message([
	                <Alert
	                    severity="error"
	                    action={
	                        <Button color="inherit" size="small" onClick={() => {set_approved_or_denied_business_message([])}}>
	                        	CLOSE
	                        </Button>
	                    }
	                    >
	                    {error.code}
	                </Alert>
	            ])
			});
	}


	const refresh_new_signup_unapproved_businesses = async () => {
		const arr = [];
		const q = query(collection(db, "businesses"), where("approved", "==", "new sign up"));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			const doc_ID = doc.id;
			const user_first_name = doc.data()["user_first_name"];
			const user_last_name = doc.data()["user_last_name"];
			const email = doc.data()["user_email"];
			const business_name = doc.data()["business_name"];
			const business_address = doc.data()["business_address_street_address"] + " "
						                 + doc.data()["business_address_city"] + " "
						                 + doc.data()["business_address_state"] + " "
						                 + doc.data()["business_address_zip_code"] + " "
			const business_category = doc.data()["business_category"]


			arr.push(
				<div> 
					<b><h2 style={{marginTop:"50px"}}> User First Name: </h2></b> 
						<h2> {user_first_name} </h2>
					<b><h2 style={{marginTop:"15px"}}> User Last Name: </h2></b> 
						<h2> {user_last_name} </h2>
					<b><h2 style={{marginTop:"15px"}}> Email: </h2></b> 
						<h2> {email} </h2>
					<b><h2 style={{marginTop:"15px"}}> Business Name: </h2></b> 
						<h2> {business_name} </h2>
					<b><h2 style={{marginTop:"15px"}}> Business Address: </h2></b> 
						<h2> {business_address} </h2>
					<b><h2 style={{marginTop:"15px"}}> Business Category: </h2></b> 
						<h2> {business_category} </h2>
					<Button color="inherit" 
					        style={{marginTop: "15px", marginLeft: "15px"}}
						    size="small" 
						    onClick={() => {approve_business(doc_ID, business_name)}}> Approve </Button>
					<Button color="inherit" 
					        style={{marginTop: "15px", marginLeft: "15px"}}
						    size="small" 
						    onClick={() => {deny_business(doc_ID, business_name)}}> Deny </Button>
					<hr></hr>
				</div>
			);
		});

		set_unapproved_businesses(arr);

	}

	useEffect(() => {
		refresh_new_signup_unapproved_businesses()
	}, [])



	if (currentUser !== process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {

		return (
			<div>
				<h1> You do not have access to this page. </h1>
			</div>
		)
	}

	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200">
			<div>
				<div>

					<h1 className="text-4xl font-bold mb-3"> Dealio Admin: New Business Approval </h1>
						{ approved_or_denied_business_message }

						{ unapproved_businesses }
				


					
				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a className = "active"> New Business Approval </a></li>
				<li><a onClick={navigate_to_AdminBilling}>Admin Billing</a></li>
				<li><a onClick={navigate_to_ViewAllBusinesses}>View All Businesses</a></li>
				<li><a onClick={navigate_to_UpdateBusinessInfo}>Update Business Info</a></li>
				<li><a onClick={navigate_to_AdminViewMetrics}>View Metrics</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default NewBusinessApproval;
