
import React, { useContext, useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {  Navigate } from "react-router";
import { AuthContext } from "../Auth.js";
import { collection, addDoc, setDoc, doc, Timestamp } from "firebase/firestore";
import { db, auth } from "../firebase";
import RadioGroup  from '@mui/material/RadioGroup'

import { Button, TextField, Alert, FormControlLabel, Radio } from '@mui/material';
import { useLocation,useNavigate,useParams } from "react-router-dom";
import Geocode from "react-geocode";
import PhoneInput from 'react-phone-number-input';
import validator from 'validator';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}


const SignUp = ({ history }) => {
    const navigate = useNavigate();
    const [name_first_entered, set_name_first_entered] = useState("")
    const [name_last_entered, set_name_last_entered] = useState("")
    const [email_entered, set_email_entered] = useState("")
    const [verify_email_entered, set_verify_email_entered] = useState("")
    const [password_entered, set_password_entered] = useState("")
    const [verify_password_entered, set_verify_password_entered] = useState("")
    const [business_name_entered, set_business_name_entered] = useState("")

    const [business_phone_number_entered, set_business_phone_number_entered] = useState();
    const [formatted_phone_number, set_formatted_phone_number] = useState("");
    const [business_address_street_address_entered, set_business_address_street_address_entered] = useState("")
    const [business_address_city_entered, set_business_address_city_entered] = useState("")
    const [business_address_state_entered, set_business_address_state_entered] = useState("")
    const [business_address_zip_code_entered, set_business_address_zip_code_entered] = useState("")
    const [business_category, set_business_category] = useState("")

    const [signup_error_alert, set_signup_error_alert] = useState([])

    const generate_random_4_digit_code = () => {
      const digit1 = Math.floor(Math.random() * (9 - 1 + 1)) + 1;
      const digit2 = Math.floor(Math.random() * (9 - 1 + 1)) + 1;
      const digit3 = Math.floor(Math.random() * (9 - 1 + 1)) + 1;
      const digit4 = Math.floor(Math.random() * (9 - 1 + 1)) + 1;
      return (digit1 * 1000) + (digit2 * 100) + (digit3 * 10) + (digit4 * 1);
    }

    const format_phone_number = (raw_phone_number) => {
        if (raw_phone_number === undefined) {return; }
        var output = "";

        if (raw_phone_number.length == 0) {
            output = "";
        } else if (raw_phone_number.length <= 3) {
            output = "(" + raw_phone_number + ")";
        } else if (raw_phone_number.length <= 6) {
            output = "(" + raw_phone_number.slice(0, 3) + ") " + raw_phone_number.slice(3);
        } else {
            output = "(" + raw_phone_number.slice(0, 3) + ") " + raw_phone_number.slice(3, 6) + " - " + raw_phone_number.slice(6, 10);
        } 

        console.log("formatted phone number: ", output);

        return output;
    }
    const update_phone_number = (new_value) => {
      var nums_only = new_value.replace(/\D/g,'');

      nums_only = nums_only.slice(0, 10);

      set_business_phone_number_entered(nums_only);
    }

    useEffect(() => {
        set_formatted_phone_number(format_phone_number(business_phone_number_entered));
    }, [business_phone_number_entered])
    
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    // set response language. Defaults to english.
    Geocode.setLanguage("en");
    

    const handleSignup = async (event) => {
        event.preventDefault();

        try {
            // check that all fields have been entered
            if (name_first_entered === "" 
                  || name_last_entered === ""
                  || email_entered === ""
                  || password_entered === ""
                  || verify_password_entered === ""
                  || business_name_entered === ""
                  || business_phone_number_entered === ""
                  || business_address_street_address_entered === ""
                  || business_address_city_entered === ""
                  || business_address_state_entered === ""
                  || business_address_zip_code_entered === ""
                  || business_category === "") {
              set_signup_error_alert([
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_signup_error_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >
                    Please make sure you've entered valid information for each field. All fields must be filled in. 
                </Alert>
              ])
              return ;
            } else if (email_entered !== verify_email_entered) {
              set_signup_error_alert([
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_signup_error_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >
                    The emails you entered do not match. Please try again. 
                </Alert>
              ]) 

              return ;
            } else if (password_entered !== verify_password_entered) {
              set_signup_error_alert([
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_signup_error_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >
                    Your passwords do not match. Please try again. 
                </Alert>
              ]) 

              return ;
            } else if (business_phone_number_entered.length !== 10) {
              set_signup_error_alert([
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_signup_error_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >
                    The phone number you enter must be 10 digits long.
                </Alert>
              ]) 

              return ;
            }

            await createUserWithEmailAndPassword(auth, email_entered, password_entered);
            await sendEmailVerification(auth.currentUser)
            const currentUserID = auth.currentUser.uid

            // assemble complete address
            const complete_business_address = String(business_address_street_address_entered) + " "
                                        + String(business_address_city_entered) + " "
                                        + String(business_address_state_entered) + " "
                                        + String(business_address_zip_code_entered)
            // Get latitude & longitude from address, then submit new business document
            Geocode.fromAddress(complete_business_address).then(async (response) => {
                console.log("Response from Google geocode: ", response);
                console.log("Formatted address: ", response.results[0]["formatted_address"]);

                const { lat, lng } = response.results[0].geometry.location;
                console.log(lat, lng);

                const four_digit_code = generate_random_4_digit_code();
                console.log("RANDOM 4 DIGIT CODE: ", four_digit_code)

                const formatted_address = response.results[0]["formatted_address"];

                const new_user_document = {
                    user_ID: currentUserID,
                    user_email: email_entered,
                    user_first_name: name_first_entered,
                    user_last_name: name_last_entered,
                    business_name: business_name_entered,
                    business_phone_number: formatted_phone_number,
                    business_address_street_address: business_address_street_address_entered,
                    business_address_city: business_address_city_entered,
                    business_address_state: business_address_state_entered,
                    business_address_zip_code: business_address_zip_code_entered,
                    business_formatted_address: formatted_address,
                    business_latitude: lat,
                    business_longitude: lng,
                    business_category: business_category,
                    approved: "new sign up",
                    four_digit_code: four_digit_code,
                    one_time_deal_is_active: true,
                    recurring_deal_is_active: true,
                    first_day_on_dealio: Timestamp.now(),
                }
                await setDoc(doc(db, "businesses", currentUserID), new_user_document);
                set_signup_error_alert([])
                navigate("/info")
              },
              (error) => {
                console.error("Geocoding error: ", error);
              }
            );

            
            
        } catch (error) {
            var error_message = error.code;
            if (error.code === "auth/email-already-in-use") {
              error_message = "The email address you entered is already in use. ";
            } else if (error.code==="auth/invalid-email") {
              error_message = "The email address you entered is invalid. Please enter a valid email and try again. ";
            } else if (error.code==="auth/invalid-password") {
              error_message = "The password you provide must have at least 6 characters. ";
            } else if (error.code==="auth/weak-password") {
              error_message = "The password you provide must have at least 6 characters. "; 
            }

            set_signup_error_alert([
              <Alert
                  severity="error"
                  action={
                      <Button color="inherit" size="small" onClick={() => {set_signup_error_alert([])}}>
                      CLOSE
                      </Button>
                  }
                  >
                  {error_message}
              </Alert>
            ])
            

            
        }
    }


    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Navigate to="/" />;
    }

    const text_field_styling = {
        marginTop: "20px",
        display: "block",
    }

    return (
        <div>

            <div className="navbar bg-base-100">
            <div className="navbar-start">
                <a className="btn btn-ghost normal-case text-xl">Dealio</a>
            </div>
            <div className="navbar-center hidden lg:flex">
            </div>
            <div className="navbar-end">
                <a className="btn" onClick={() => {navigate("/login")}}>Log In</a>
            </div> 
            </div>
            <div class="hero flex flex-col">
            <b><h1 style={{fontSize: "30px"}}> Create a Dealio Business Account </h1></b>

                    {signup_error_alert}

            <form onSubmit={handleSignup}>
                



                <b><h1 style={{marginTop: "50px", fontSize: "20px"}}> Basic Details* </h1></b>

                <TextField label="First Name*"
                           value={name_first_entered}
                           onChange={(event) => {set_name_first_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

               <TextField label="Last Name*"
                           value={name_last_entered}
                           onChange={(event) => {set_name_last_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                <TextField label="Email*"
                           value={email_entered}
                           onChange={(event) => {set_email_entered(event.target.value)}}
                           variant="outlined"
                           type="email"
                           style={text_field_styling} />

                <TextField label="Verify Email*"
                           value={verify_email_entered}
                           onChange={(event) => {set_verify_email_entered(event.target.value)}}
                           variant="outlined"
                           type="email"
                           style={text_field_styling} />

                <TextField label="Password*"
                           value={password_entered}
                           onChange={(event) => {set_password_entered(event.target.value)}}
                           variant="outlined"
                           type="password"
                           style={text_field_styling} />

                <TextField label="Verify Password*"
                           value={verify_password_entered}
                           onChange={(event) => {set_verify_password_entered(event.target.value)}}
                           variant="outlined"
                           type="password"
                           style={text_field_styling} />

                <b><h1 style={{marginTop: "50px", fontSize: "20px"}}> Business Details* </h1></b>

                <TextField label="Business Name*"
                           value={business_name_entered}
                           onChange={(event) => {set_business_name_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                <TextField label="Phone Number*"
                           value={business_phone_number_entered}
                           onChange={(event) => {update_phone_number(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                {formatted_phone_number}

                <TextField label="Street Address*"
                           value={business_address_street_address_entered}
                           onChange={(event) => {set_business_address_street_address_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                <TextField label="City*"
                           value={business_address_city_entered}
                           onChange={(event) => {set_business_address_city_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                <TextField label="State*"
                           value={business_address_state_entered}
                           onChange={(event) => {set_business_address_state_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                <TextField label="Zip Code*"
                           value={business_address_zip_code_entered}
                           onChange={(event) => {set_business_address_zip_code_entered(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={text_field_styling} />

                <b><h1 style={{marginTop: "50px", fontSize: "20px"}}> Business Category* </h1></b>

                <RadioGroup>
                        <FormControlLabel value="food" control={<Radio />} label="Food" 
                                          onClick={() => set_business_category("Food")} />
                        <FormControlLabel value="health_beauty" control={<Radio />} label="Health & Beauty" 
                                          onClick={() => set_business_category("Health & Beauty")} />
                        <FormControlLabel value="grocery" control={<Radio />} label="Grocery" 
                                          onClick={() => set_business_category("Grocery")} />
                        <FormControlLabel value="clothing" control={<Radio />} label="Clothing" 
                                          onClick={() => set_business_category("Clothing")} />
                        <FormControlLabel value="fitness" control={<Radio />} label="Fitness" 
                                          onClick={() => set_business_category("Fitness")} />
                        <FormControlLabel value="home_garden" control={<Radio />} label="Home & Garden" 
                                          onClick={() => set_business_category("Home & Garden")} />
                        <FormControlLabel value="automotive" control={<Radio />} label="Automotive" 
                                          onClick={() => set_business_category("Automotive")} />
                        <FormControlLabel value="gifts_florists" control={<Radio />} label="Gifts & Florists" 
                                          onClick={() => set_business_category("Gifts & Florists")} />
                        <FormControlLabel value="home_maintenance" control={<Radio />} label="Home Maintenance" 
                                          onClick={() => set_business_category("Home Maintenance")} />   
                        <FormControlLabel value="entertainment" control={<Radio />} label="Entertainment" 
                                          onClick={() => set_business_category("Entertainment")} />
                        <FormControlLabel value="pets_animal_care" control={<Radio />} label="Pets & Animal Care" 
                                          onClick={() => set_business_category("Pets & Animal Care")} />
                        <FormControlLabel value="sporting_goods" control={<Radio />} label="Sporting Goods" 
                                          onClick={() => set_business_category("Sporting Goods")} />  
                        <FormControlLabel value="retail" control={<Radio />} label="Retail" 
                                          onClick={() => set_business_category("Retail")} />                                                                                                          
                </RadioGroup>

                <Button variant="contained"
                        type="submit"
                        style={{fontSize: "16px", display: "block", margin: "auto", marginTop: "30px", marginBottom: "50px"}}>
                    Sign Up
                </Button>
            </form>
            </div>
        </div>

    );
};

export default withRouter(SignUp);
