import { useNavigate } from "react-router-dom"

export default function PasswordReset({history}) {
    const navigate = useNavigate()
    return (
        <>
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg></label>
                <a className="btn btn-ghost normal-case text-xl" onClick={() => {navigate("/")}}>Dealio</a>
            </div>
            <div className="navbar-center hidden lg:flex">
            </div>
            <div className="navbar-end">
                <a className="btn" onClick={() => {navigate("/logout")}}>Log Out</a>
            </div>
        </div>
        </>
    )
}