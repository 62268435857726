import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { Button, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { storage, db, auth } from "../firebase";



export function PasswordReset({history}) {
    const [email_entered, set_email_entered] = useState("")
    let navigate = useNavigate();


    const handle_reset_password = async (event) => {
        event.preventDefault();
        try {
            sendPasswordResetEmail(auth, email_entered)
            set_page_content(
                [   <div style={{textAlign: "center"}}>
                        <p>
                            Please check your email to reset your password. (Make sure to check your spam folder too!)
                        </p>
                    </div>
                ]
            )
            console.log("Sent password-reset email! ")
        } catch (error) {
            console.log(error);
        }
    }

    const text_field_styling = {
        marginTop: "20px"
    }


    const [page_content, set_page_content] = useState([])



    return (
        <div>
            <div className="navbar bg-base-100">
            <div className="navbar-start">
                <a className="btn btn-ghost normal-case text-xl">Dealio</a>
            </div>
            <div className="navbar-center hidden lg:flex">
            </div>
            <div className="navbar-end">
                <a className="btn" onClick={() => {navigate("/login")}}>Log In</a>
            </div>
            </div>
            <div className="hero">
            <form onSubmit={handle_reset_password}>
                <TextField label="Email"
                           value={email_entered}
                           onChange={(event) => {
                               console.log(event.target.value)
                               set_email_entered(event.target.value)
                           }}
                           variant="outlined"
                           type="email"
                           style={text_field_styling} />

                <Button type="submit"
                        style={{display: "block", margin: "auto"}}>
                    Reset Password
                </Button>
            </form>
            </div>
            {page_content}
        </div>

    )
}


export default PasswordReset;
