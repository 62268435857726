
import React, { useEffect, useState } from "react";
import { storage, db, auth } from "../firebase";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./UpdateImages.css"
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc, and, or } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";
import { Button, TextField, Alert } from '@mui/material';



export function AdminBilling(props) {

	const navigate = useNavigate()
    const currentUser = auth.currentUser.uid
    const [outstanding_billings, set_outstanding_billings] = useState([]);
    const [outstanding_billings_2, set_outstanding_billings_2] = useState([]);


    const navigate_to_NewBusinessApproval = () => {
    	navigate("/dealio_admin")
    }
  	const navigate_to_AdminBilling = () => {
    	navigate("/admin_billing")
    }
    const navigate_to_ViewAllBusinesses = () => {
    	navigate("/admin_view_all_businesses")
    }
    const navigate_to_UpdateBusinessInfo = () => {
    	navigate("/admin_update_business_info")
    }
    const navigate_to_AdminViewMetrics = () => {
    	navigate("/admin_view_metrics")
    }

    function difference_in_days(start, end) {  
	    var output = Math.round((end - start) / (1000 * 60 * 60 * 24));   
    	// console.log("Difference in days: ")
    	// console.log("start: ", start)
    	// console.log("end: ", end)
    	// console.log("difference in days: ", output )
	    return output;
	}


	// getMonth+1, getDate, getYear+1900
	const generate_new_bills = async() => {
		

		// for all businesses
		const arr = [];
		const q = query(collection(db, "businesses"), where("approved", "==", "true"));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (doc) => {
			var curr_date = new Date();
			const doc_ID = doc.id;
			const business_name = doc.data()["business_name"];
			const email = doc.data()["user_email"];
			const first_day_on_dealio = doc.data()["first_day_on_dealio"].toDate()

			// console.log("business name: ", business_name)
			// console.log("first day on Dealio: ", first_day_on_dealio)


			// make sure all bills have been generated for this business within the past 31 days
			for (var i = 0; i < 31; i++) {
				// console.log("NEW CURR DATE: ", curr_date)

				if (curr_date >= first_day_on_dealio && difference_in_days(first_day_on_dealio, curr_date) % 14 == 0) {
					// check if bill has been generated. if not, generate bill details (these details are entered into a Stripe Payment Link, and the Stripe Payment Link is sent to the business)
					var month = curr_date.getMonth() + 1;
					var day_of_month = curr_date.getDate();
					var year = curr_date.getYear() + 1900;

					const query_bill_exists = query(collection(db, "billings"), and(
							where("business_id", "==", doc_ID),
							where("billing_month", "==", month),
							where("billing_day_of_month", "==", day_of_month),
							where("billing_year", "==", year),
						))

		
					const querySnapshot = await getDocs(query_bill_exists);

					if (querySnapshot.size == 0) {
						// generate new bill starting on curr_date - 14, ending on curr_date
						var ending_date = new Date(year, month-1, day_of_month);

						var beginning_date = new Date(year, month-1, day_of_month);
						beginning_date.setDate(beginning_date.getDate() - 14)

						const query_redemptions_in_14_day_period = query(collection(db, "redemptions"), and(
								where("business_id", "==", doc_ID),
								where('time', '>=', beginning_date),
								where('time', '<', ending_date),	
							))

						var counter = 0;

						const querySnapshot = await getDocs(query_redemptions_in_14_day_period);
						const num_redemptions = querySnapshot.size

						// console.log("\n\n")
						// console.log("BEGINNING DATE: " + beginning_date)
						// console.log("ENDING DATE: " + ending_date)
						// console.log("Business Name: " + business_name)
						// console.log("Email: " + email)
						// console.log("First Day on Dealio: " + first_day_on_dealio)
						// console.log("Number of redemptions in last 14-day period: " + num_redemptions)

						// add billing document to billings collection 
						const docRef = await addDoc(collection(db, "billings"), {
						  business_id: doc_ID,
						  business_name: business_name,
						  business_email: email,
						  beginning_date: beginning_date,
						  ending_date: ending_date,
						  num_redemptions: num_redemptions,
						  charge_amount_cents: 50 * num_redemptions,
						  billing_month: month,
						  billing_day_of_month: day_of_month,
						  billing_year: year,
						  invoice_sent: false,
						  payment_received: false,
						});

						
					}

				}
				// increment date
				curr_date.setDate(curr_date.getDate() - 1)

			}

			
		});

	}

	const mark_invoice_as_sent_for_billing = async (event, doc_ID) => {
		const docRef = doc(db, "billings", doc_ID);
		await setDoc(docRef, {
			invoice_sent: true
		}, {merge: true});

		display_outstanding_billings()
	}

	const mark_payment_as_received_for_billing = async (event, doc_ID) => {
		const docRef = doc(db, "billings", doc_ID);
		await setDoc(docRef, {
			payment_received: true
		}, {merge: true});

		display_outstanding_billings()
	}

	const display_outstanding_billings = async () => {
		var arr = [];
		var arr_2 = [];

		const query_outstanding_billings = query(collection(db, "billings"), or(
				where("invoice_sent", "==", false),
				where("payment_received", "==", false)
			));

		const querySnapshot = await getDocs(query_outstanding_billings);
		querySnapshot.forEach(async (doc) => {
			arr_2.push(
				<tr>
					<td style={{border: "1px solid black", textAlign: "center"}}> {doc.data()["business_name"]} </td>
					<td style={{border: "1px solid black", textAlign: "center"}}> {doc.data()["business_email"]} </td>
					<td style={{border: "1px solid black", textAlign: "center"}}> {doc.data()["billing_month"] + "/" + doc.data()["billing_day_of_month"] + "/" + doc.data()["billing_year"]} </td>
					<td style={{border: "1px solid black", textAlign: "center"}}> {doc.data()["num_redemptions"]} </td>
					<td style={{border: "1px solid black", textAlign: "center"}}> {doc.data()["charge_amount_cents"] / 100} </td>
					<td style={{border: "1px solid black"}}> <Button color="inherit" 
							disabled={doc.data()["invoice_sent"]}
					        style={{marginTop: "15px", marginLeft: "15px"}}
						    size="small" 
						    onClick={(event) => {mark_invoice_as_sent_for_billing(event, doc.id)}}> Invoice Sent </Button> </td>
					<td style={{border: "1px solid black"}}> <Button color="inherit" 
					        style={{marginTop: "15px", marginLeft: "15px"}}
						    size="small" 
						    onClick={(event) => {mark_payment_as_received_for_billing(event, doc.id)}}> Payment Received </Button> </td>
				</tr>
			)

			arr.push(
				<div> 
					<b><h2 style={{marginTop:"50px"}}> Business Name: </h2></b> 
						<h2> {doc.data()["business_name"]} </h2>
					<b><h2 style={{marginTop:"15px"}}> Email: </h2></b> 
						<h2> {doc.data()["business_email"]} </h2>
					<b><h2 style={{marginTop:"15px"}}> Billing Date: </h2></b> 
						<h2> {doc.data()["billing_month"] + "/" + doc.data()["billing_day_of_month"] + "/" + doc.data()["billing_year"]} </h2>
					<b><h2 style={{marginTop:"15px"}}> # Deal Redemptions: </h2></b> 
						<h2> {doc.data()["num_redemptions"]} </h2>
					<b><h2 style={{marginTop:"15px"}}> Charge Amount (Dollars): </h2></b> 
						<h2> {doc.data()["charge_amount_cents"] / 100} </h2>
					
					<Button color="inherit" 
							disabled={doc.data()["invoice_sent"]}
					        style={{marginTop: "15px", marginLeft: "15px"}}
						    size="small" 
						    onClick={(event) => {mark_invoice_as_sent_for_billing(event, doc.id)}}> Invoice Sent </Button>
					<Button color="inherit" 
					        style={{marginTop: "15px", marginLeft: "15px"}}
						    size="small" 
						    onClick={(event) => {mark_payment_as_received_for_billing(event, doc.id)}}> Payment Received </Button>
					<hr></hr>
				</div>

			)

		});

		set_outstanding_billings(arr);
		set_outstanding_billings_2(arr_2);

	}

	useEffect(() => {
		console.log("NEW BILLINGS: ")
		generate_new_bills()
		display_outstanding_billings()
	}, [])

	
	if (currentUser !== process.env.REACT_APP_DEALIO_ADMIN_FIREBASE_USER_ID) {
		return (
			<div>
				<h1> You do not have access to this page. </h1>
			</div>
		)
	}


	return (
		<div>
            <div class="hero flex flex-col"></div>
			<div className="drawer drawer-mobile">
			<input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content flex flex-col items-left justify-left">
			<div className="hero bg-base-200">
			<div>
				<div>

					<h1 className="text-4xl font-bold mb-3"> Admin Billing: Outstanding Bills </h1>
					<table style={{margin: "12px"}}>
						<tr>
							<th style={{border: "1px solid black"}}> Business Name </th>
							<th style={{border: "1px solid black"}}> Email </th>
							<th style={{border: "1px solid black"}}> Billing Date </th>
							<th style={{border: "1px solid black"}}> # Deal Redemptions </th>
							<th style={{border: "1px solid black"}}> Charge Amount (Dollars) </th>
							<th style={{border: "1px solid black"}}> Invoice Sent </th>
							<th style={{border: "1px solid black"}}> Payment Received </th>
						</tr>

						{outstanding_billings_2}
					</table>				

				</div>
			</div>
			</div>
			</div> 
			<div className="drawer-side">
			<label htmlFor="my-drawer-2" className="drawer-overlay"></label> 
			<ul className="menu p-4 w-80 bg-base-100 text-base-content">
				<li><a onClick={navigate_to_NewBusinessApproval}>New Business Approval</a></li>
				<li><a className = "active"> Admin Billing </a></li>
				<li><a onClick={navigate_to_ViewAllBusinesses}>View All Businesses</a></li>
				<li><a onClick={navigate_to_UpdateBusinessInfo}>Update Business Info</a></li>
				<li><a onClick={navigate_to_AdminViewMetrics}>View Metrics</a></li>
			</ul>
			
			</div>
		</div>
		</div>
	);
}




export default AdminBilling;
